import React from 'react'
import './style.css'

export const Buttons = ({text,className,onClick, disabled}) => {
    return (
        <button className={className} onClick={onClick} disabled={disabled}>
        {text} 
        </button>
    )
}
