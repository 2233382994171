import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import axiosInstance from '../../../utils/axiosInstance';
import getRandomStyle from '../../../utils/randomStyle';
import Loading from '../loading/Loading';
import DynamicIcon from '../../../utils/DynamicIcon';

export const MarkdownEtapaAnterior = ({
  value,
  onChange,
  disabled,
  idsubetapa,
}) => {
  const [open, setOpen] = useState(false);
  const [style, setStyle] = useState({});
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [catalogo, setCatalogo] = useState('');
  const containerRef = React.useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleclickInput = () => {
    if (disabled || !catalogo) {
      return;
    }
    setOpen(!open);
  };

  const handleSelectOpcion = item => {
    if (disabled || !catalogo) {
      return;
    }
    setOpen(false);
    onChange(item ? item.descripcion : '');
    setStyle(item ? item.style : {});
  };

  const fetchCatalogo = async () => {
    try {
      setIsLoading(true);

      if (options.length) {
        return;
      }

      if (catalogo) {
        let result = await axiosInstance.get(
          `/catalogos?nombre_catalogo=${catalogo}`,
        );
        result.data.elementos.forEach(item => {
          item.style = getRandomStyle('etapa_anterior');
        });
        setOptions(result.data.elementos || []);
      }
    } catch (err) {
      toast.error('Error al obtener el catalogo de sub etapas');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchCatalogo();
    }
  }, [open]);

  useEffect(() => {
    let result = '';

    switch (+idsubetapa) {
      case 4:
        result = 'catalogorechazodictamen';
        break;
      case 6:
        result = 'catalogorechazoexpediente';
        break;
      case 7:
        result = 'catalogorechazocontacto';
        break;
      case 8:
        result = 'catalogorechazovisita';
        break;
      case 12:
        result = 'catalogorechazocontrato';
        break;
      case 14:
        result = 'catalogorechazofirmas';
        break;
      default:
        break;
    }

    setCatalogo(result);
  }, [idsubetapa]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`markdownContainer ${disabled || !catalogo ? 'disabled' : ''}`}
      style={style}
      ref={containerRef}
    >
      <div className="markdownContainerTextButtonClear">
        <input
          type="text"
          onClick={handleclickInput}
          className="markdownContainerTextButtonClear__input"
          value={value}
          readOnly
          style={style}
          id="markdowninput"
        />

        <div class=" css-1hb7zxy-IndicatorsContainer mrimg">
          <span class=" css-1okebmr-indicatorSeparator"></span>
          <div class=" css-tlfecz-indicatorContainer" aria-hidden="true">
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              class="css-tj5bde-Svg"
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </div>
        </div>
        {value && !disabled && catalogo && (
          <div
            className="markdownContainerTextButtonClear__button"
            onClick={() => handleSelectOpcion(null)}
          >
            <DynamicIcon fill={style ? style.color : ''} />
          </div>
        )}
      </div>

      <ul className={`markdownMenu  ${open ? 'markdownView' : ''}`}>
        {isLoading && <Loading />}

        {!isLoading &&
          (options || []).map((opcion, index) => (
            <li
              key={index}
              className="markdownList"
              style={opcion.style || { color: 'black' }}
              onClick={() => handleSelectOpcion(opcion)}
            >
              {opcion.descripcion}
            </li>
          ))}
      </ul>
    </div>
  );
};
