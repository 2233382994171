import React from 'react';
import logo from '../../../../assets/img/logo/logo.png';
import './style.css'

export const HeaderLogin = () => {

    return (
        <header className="header fixed-top">
            <div className="container bod">
                <div className="py-2 pl-2 w-2/5 lg:w-1/5">
                    <a href="#!">
                        <img src={logo} alt="logo" className="logo"/>
                    </a>
                </div>
            </div>
        </header>
    )
}