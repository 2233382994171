import React, { useEffect, useState } from 'react';
import { Title } from '../../../components/Atoms/title';
import { Tables } from '../../../components/Moleculas/pantallas/accesos/tables';
import { Search } from '../../../components/Moleculas/pantallas/accesos/search';
import toast from 'react-hot-toast';
import axiosInstance from '../../../utils/axiosInstance';

export const GestiondeUsuarios = () => {
  const [usersList, setUsersList] = useState([]);
  const [usersAll, setUsersAll] = useState([]);
  const [message, setMessage] = useState('');

  const updateTable = users => {
    setUsersList(users);
  };

  const updateMessage = message => {
    setMessage(message);
  };

  const fetchData = async () => {
    try {
      let result = await axiosInstance(`/accesos/usuarios`);
      const dataUser = result.data;

      const newUsersIndex = dataUser.usuarios.map((user, index) => {
        return { ...user, index: `${index + 1}` };
      });
      setUsersList(newUsersIndex);
      setUsersAll(newUsersIndex);
    } catch (err) {
      toast.error('Error al consultar los usuarios');
    } finally {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Title text="Gestión de usuarios" />
      <Search
        placeholder="Búsqueda"
        usersList={usersList}
        usersAll={usersAll}
        updateTable={updateTable}
        updateMessage={updateMessage}
      />
      {usersList.length === 0 ? (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <Tables
          usersList={usersList}
          setUsersList={setUsersList}
          message={message}
        />
      )}
    </>
  );
};
