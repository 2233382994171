import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import rightarrow from '../../../../assets/img/icons/right-arrow.svg';
import './style.css';
import fernet from 'fernet';
import { callcookie } from '../../../Helpers';
import { apiRoot, tokenSecret } from '../../../../constants';

export const FormLogin = withRouter((props) => {
  const [emailError, setEmailError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  const loginWithTokenUrl = async () => {
    try{
      let queryParams = new URLSearchParams(window.location.search);
      let externalToken = queryParams.get('token');
      setLoading(true);

      if(externalToken){

        let responseToken = await axios.post(`${process.env.REACT_APP_API_KONG}/accesos/login/interno`, {}, {
          headers: {
            'authorization': `Bearer ${externalToken}`
          }
        });
        let responseData = responseToken.data;
        let secret = new fernet.Secret(`${tokenSecret}`);
        let token = new fernet.Token({
          secret: secret,
          time: Date.parse(1),
          iv: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        });

        callcookie('accesstoken', responseData.access_token, 1);
        callcookie('accessuser', token.encode(responseData.rol), 1);
        callcookie('user', token.encode(responseData.email), 1);
        setEmailError('');

        props.history.push('/');
      }
    } catch(err){
      console.error('Error al generar nuevo token', err);
    }finally{
      setLoading(false);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      let secret = new fernet.Secret(`${tokenSecret}`);
      var token = new fernet.Token({
        secret: secret,
        time: Date.parse(1),
        iv: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      });
      const passwordencode = token.encode(user.password);

      var myHeaders = new Headers();
      myHeaders.append('access_token', '1321412');
      myHeaders.append('Content-Type', 'application/json');

      var raw = JSON.stringify({
        correo: user.email,
        password: passwordencode,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };
      //Cambio async - error
      fetch(`${apiRoot}/accesos/login`, requestOptions)
        .then(response => response.text())
        .then(result => {
          const resultado = JSON.parse(result);

          const resultadoSucces = resultado;
          if (resultadoSucces.access_token) {
            const autoken = resultado.access_token;

            const rol = resultado.rol;
            const rolEncode = token.encode(rol);

            const email = user.email;
            const emailEncode = token.encode(email);

            callcookie('accesstoken', autoken, 1);
            callcookie('accessuser', rolEncode, 1);
            callcookie('user', emailEncode, 1);
            setEmailError('');
            window.location.href = '/';
          }

          const errorResultado = resultado;
          if (errorResultado.detail === 'No autorizado') {
            var email = (document.getElementById('floatingUser').value = '');
            var password = (document.getElementById('floatingPassword').value =
              '');
            setEmailError('Correo y/o contraseña inválidos');
          }
        })
        .catch(error => console.log(error));
    } catch (error) {
      setEmailError('Error de conexion');
    }
  };

  const mostrarContrasena = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = ({ target: { value, name } }) => {
    setUser({ ...user, [name]: value });
    const val = document.getElementById('floatingUser').value;
    if (val.length > 0 === true) {
      const claseStyle = window.document.getElementsByClassName('emailLabel');
      for (var i = 0; i < claseStyle.length; i++) {
        claseStyle[i].classList.add('ligth');
      }
    } else {
      const claseStyle = window.document.getElementsByClassName('emailLabel');
      for (var o = 0; o < claseStyle.length; o++) {
        claseStyle[o].classList.remove('ligth');
      }
    }

    const valP = document.getElementById('floatingPassword').value;
    if (valP.length > 0 === true) {
      const claseStylepass =
        window.document.getElementsByClassName('passwordLabel');
      for (var r = 0; r < claseStylepass.length; r++) {
        claseStylepass[r].classList.add('ligth');
      }
    } else {
      const claseStyleprm =
        window.document.getElementsByClassName('passwordLabel');
      for (var a = 0; a < claseStyleprm.length; a++) {
        claseStyleprm[a].classList.remove('ligth');
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      loginWithTokenUrl();
    }, 1500);
  }, []);

  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <label className="basic" htmlFor="email">
          Ingresa tu email<span className="text-red">*</span>
        </label>
        <div className="form-floating has-feedback">
          <input
            type="email"
            name="email"
            onChange={handleChange}
            className="form-control inputlogin"
            id="floatingUser"
            placeholder="email"
            disabled={loading}
          />
          <label htmlFor="floatingUser" className="emailLabel">
            Email
          </label>
          <span
            className="fa fa-envelope form-control-feedback"
            aria-hidden="true"
          ></span>
        </div>

        <label className="basic">
          Escribe tu contraseña<span className="text-red">*</span>
        </label>
        <div className="form-floating has-feedback">
          <input
            type={showPassword ? 'text' : 'password'}
            name="password"
            onChange={handleChange}
            className="form-control inputlogin"
            id="floatingPassword"
            placeholder="Password"
            disabled={loading}
          />
          <label htmlFor="floatingPassword" minLength="6" className="passwordLabel">
            Contraseña
          </label>
          <button
            className={`form-control-feedback buttonEyes ${
              showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
            }`}
            type="button"
            onClick={mostrarContrasena}
          />
        </div>
        <button className="button-login" type="submit" disabled={loading}>
          {' '}
          Iniciar Sesión{' '}
          <img src={rightarrow} alt="right-arrow" className="right-arrow" />
        </button>

        {emailError.length > 0 && (
          <div className="divstyleAlert">
            <label className="styleAlert text-red">{emailError}</label>
          </div>
        )}
      </form>
    </>
  );
});


// export default withRouter(FormLogin);
