import React, { useState, useEffect } from 'react';
import { ModalBody, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import axiosInstance from '../../../../utils/axiosInstance';
import Loading from '../../loading/Loading';
import toast from 'react-hot-toast';
import { etapaExpediente, idSubetapaDictamen, idSubetapaMesacredito } from '../../../../utils/constants'

export const ModalNumeroSolicitud = ({ solicitud, handleModalClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSolicitud, setDataSolicitud] = useState([]);
  const [regimenFiscal, setRegimenFiscal] = useState('');

  const fetchData = async () => {
    try {
      // console.log({
      //   solicitud,
      //   dataSolicitud,
      // });
      setIsLoading(true);
      let result = await axiosInstance(`/solicitudes/consulta_rol?id_solicitud=${solicitud.numsolicitud}&id_workflow=1&campo=detallesolicitud`);
      if (result.data.detallesolicitud) {

        let solicitud_data = [];
        let regimen_fiscal = '';

        result.data.detallesolicitud.forEach(item1 => {
          Object.entries(item1).forEach(item2 => {
            if (item2[0] !== 'Número empleados') {
              solicitud_data.push(item2);
            }

            if (item2[0] == 'Régimen Fiscal') {
              regimen_fiscal = item2[1] === 'Persona Moral' ? 'pm' : 'pfae';
            }
          });
        });
        setDataSolicitud(solicitud_data);
        setRegimenFiscal(regimen_fiscal);
      }
    } catch (err) {
      console.log(err);
      toast.error('Error al consultar la solicitud');
    } finally {
      setIsLoading(false);
    }
  };

  const openClickLess = (seccion, regimenFiscal, idSolicitud) => {
    let payload = {
      eventType: 'openLink',
      seccion,
      regimenFiscal,
      idSolicitud,
    };
    window.parent.postMessage(payload, '*');
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="popupcontent modal fade show">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <span className="titleHeader">Datos de la solicitud</span>
            <div>
              <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true" onClick={handleModalClose}>
                  ×
                </span>
              </button>
            </div>
          </div>
          <ModalBody className="modal_custom">

            {isLoading &&
              <Loading style={{ display: 'flex' }} />
            }

            <Row>
              <Col md={12}>
                {!isLoading &&
                  <>
                    {(!dataSolicitud || dataSolicitud.length === 0) &&
                      <span>No hay información disponible</span>
                    }

                    {dataSolicitud && dataSolicitud.map((item, index) => (
                      <FormGroup row key={index}>
                        <Label sm={4}>{item[0]}</Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            disabled
                            value={item[1]}
                          />
                        </Col>
                      </FormGroup>
                    ))}
                  </>
                }
              </Col>
            </Row>

            {dataSolicitud && regimenFiscal !== '' && (
              <Row className="mt-4">
                <Col md={12} style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                }}>
                  <button
                    className="btn btn-secondary"
                    onClick={() => openClickLess('documentos', regimenFiscal, solicitud.numsolicitud) }
                  >
                    Ir al Expediente
                  </button>

                  {solicitud.etapa == etapaExpediente && solicitud.idsubetapa == idSubetapaDictamen && (
                    <button
                      onClick={() => openClickLess('dictamen', regimenFiscal, solicitud.numsolicitud) }
                      className="btn btn-secondary"
                    >
                      Ir al dictamen
                    </button>
                  )}

                  {solicitud.etapa == etapaExpediente && solicitud.idsubetapa == idSubetapaMesacredito && (
                    <button
                      onClick={() => openClickLess('mesa_credito_inicial', regimenFiscal, solicitud.numsolicitud) }
                      className="btn btn-secondary"
                    >
                      Ir a validación de expediente
                    </button>
                  )}
                </Col>
              </Row>
            )}
          </ModalBody>
        </div>
      </div>
    </div>
  );
};