import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../utils/isLogin';
import { MainLayout } from '../components/Layout';
import PageSpinner from '../components/Moleculas/generic/PageSpinner'

const PrivateRoute = ({component: Component, ...rest}) => {
    
    return (
        <Route  {...rest} render={props => (
            isLogin() ?
            <MainLayout breakpoint={props.breakpoint}>
                <React.Suspense fallback={<PageSpinner />}>
                    <Component {...props} />
                </React.Suspense>
            </MainLayout>
            : <Redirect to="/login" />
        )} />
    );
};

export default PrivateRoute