import React, { useEffect, useState } from 'react';
import { Title } from '../../../components/Atoms/title';
import { TableSticky } from '../../../components/Moleculas/pantallas/flujos/asesorTeamleaderenAlianzas/tableSticky';
import { MdSearch, MdFilterList } from 'react-icons/md';
import { getCookiesValue } from '../../../components/Helpers';
import { SUBETAPAS_POR_ROL } from '../../../utils/constants';
import decodeToken from '../../../utils/decodeToken';
import {
  Col,
  Card,
  InputGroup,
  InputGroupAddon,
  Input,
  Row,
  Button,
  FormGroup,
  Label,
} from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import es from 'date-fns/locale/es';
import toast from 'react-hot-toast';
import 'react-datepicker/dist/react-datepicker.css';
import filterString from '../../../utils/filterString';
import axiosInstance from '../../../utils/axiosInstance';
import getRandomStyle from '../../../utils/randomStyle';

export const AsesorTeamleaderenAlianzas = () => {
  const [tableContratacionData, setTableContratacionData] = useState([]);
  const [tableAutorizacionData, setTableAutorizacionData] = useState([]);
  const [tableExpedienteData, setTableExpedienteData] = useState([]);
  const [tableClienteData, setTableClienteData] = useState([]);
  const [tableEtapaData, setTableEtapaDataList] = useState([]);
  const [tablesubEtapaData, setTableSubEtapaDataList] = useState([]);
  const [tablestatusData, setTableStatusDataList] = useState([]);
  const [show, setShow] = useState(false);
  const [etapaPrincipal, setEtapaPrincipal] = useState('');
  const [etapa, setEtapa] = useState('');
  const [subEtapa, setSubetapa] = useState('');
  const [estatus, setEstatus] = useState('');
  const accessuser = decodeToken(getCookiesValue('accessuser'));
  const subEtapasPermitidas = SUBETAPAS_POR_ROL[accessuser.toLowerCase()] || [];
  const fechaInicioAnio = moment().startOf('year');
  const fechaActual = moment();
  const fechaMin = moment().subtract(2, 'years').toDate();
  const fechaMax = moment().toDate();
  const [fechaInicio, setFechaInicio] = useState(fechaInicioAnio.toDate());
  const [fechaFin, setFechaFin] = useState(fechaActual.toDate());
  const [isLoading, setIsLoading] = useState(false);
  const [numeroSolicitud, setNumeroSolicitud] = useState('');
  const [catalogoPerdidaInteres, setCatalogoPerdidaInteres] = useState([]);
  const [catalogoRechazo, setCatalogoRechazo] = useState([]);
  const [showBotonLimpiar, setShowBotonLimpiar] = useState(false);

  // el estatus de la solicitud debe ser diferente a rechazada
  // la subetapa de la solicitud debe ser una de las asignadas al rol del usuario
  const validar_solicitudes_editables = solicitudes => {
    solicitudes.forEach((solicitud, index) => {
      let esEditable = false;
      if (solicitud.estatus !== 'rechazada') {
        esEditable = subEtapasPermitidas.includes(+solicitud.idsubetapa);
      }
      solicitud.index = index + 1;
      solicitud.esEditable = esEditable;
    });

    return solicitudes;
  };

  const fetchCatalogos = async () => {
    try {
      let results = await Promise.all([
        axiosInstance.get(`/catalogos?nombre_catalogo=catalogoetapas`),
        axiosInstance.get(`/catalogos?nombre_catalogo=catalogosubetapas`),
        axiosInstance.get(`/catalogos?nombre_catalogo=catalogoestatus`),
        axiosInstance.get(`catalogos?nombre_catalogo=catalogoperdidainteres`),
        axiosInstance.get(
          `/catalogos?nombre_catalogo=catalogorechazo&rol=true`,
        ),
      ]);

      let catalogo_etapas = results[0];
      let catalogo_subetapas = results[1];
      let catalogo_estatus = results[2];
      let catalogo_perdida_interes = results[3];
      let catalogo_rechazo = results[4];

      // generar estilos aleatorios
      let options_perdida_interes = [];

      catalogo_perdida_interes.data.elementos.forEach((item, index) => {
        options_perdida_interes.push({
          style: getRandomStyle('cliente_sin_interes'), // estilos para el multiselect
          value: item.descripcion,
          label: item.descripcion,
        });
      });

      // generar estilos aleatorios
      catalogo_rechazo.data.elementos.forEach(item => {
        item.style = getRandomStyle('rechazar');
      });

      setTableEtapaDataList(catalogo_etapas.data.elementos);
      setTableSubEtapaDataList(catalogo_subetapas.data.elementos);
      setTableStatusDataList(catalogo_estatus.data.elementos);
      setCatalogoPerdidaInteres(options_perdida_interes);
      setCatalogoRechazo(catalogo_rechazo.data.elementos);
    } catch (err) {
      console.log('Error al consultar los catalogos', err);
      if (err && err.response && err.response.status == 503) {
        console.log('status', err.response.status);
      } else {
        toast.error('Error al consultar los catalogos');
        console.log('Error al consultar los catalogos', err);
      }
    }
  };

  const fetchData = async (
    _fechaInicio,
    _fechaFin,
    _subEtapa,
    _estatus,
    _etapa,
  ) => {
    try {
      setIsLoading(true);
      setEtapaPrincipal(_etapa || '');

      // cerrar el buscador si es movil
      if (window.innerWidth < 578) {
        setShow(false);
      }

      let _fecha_inicio = _fechaInicio
        ? moment(_fechaInicio).format('YYYY-MM-DD')
        : fechaInicioAnio.format('YYYY-MM-DD');
      let _fecha_fin = _fechaFin
        ? moment(_fechaFin).format('YYYY-MM-DD')
        : fechaActual.format('YYYY-MM-DD');

      // validar si hay filtros seleccionados
      let tiene_filtros =
        _subEtapa ||
        _estatus ||
        _etapa ||
        _fecha_inicio !== fechaInicioAnio.format('YYYY-MM-DD') ||
        _fecha_fin != fechaActual.format('YYYY-MM-DD')
          ? true
          : false;

      // armar las urls
      let url_responseAutorizacion = `/solicitudes/consulta?fecha_inicio=${_fecha_inicio}&fecha_fin=${_fecha_fin}&id_workflow=1&etapa=Autorizacion&rol=true`;
      let url_responseExpediente = `/solicitudes/consulta?fecha_inicio=${_fecha_inicio}&fecha_fin=${_fecha_fin}&id_workflow=1&etapa=Expediente&rol=true`;
      let url_responseContratacion = `/solicitudes/consulta?fecha_inicio=${_fecha_inicio}&fecha_fin=${_fecha_fin}&id_workflow=1&etapa=Contratacion&rol=true`;
      let url_responseCliente = `/solicitudes/consulta?fecha_inicio=${_fecha_inicio}&fecha_fin=${_fecha_fin}&id_workflow=1&etapa=Cliente&rol=true`;

      if (_subEtapa) {
        url_responseAutorizacion += `&subetapa=${_subEtapa}&rol=true`;
        url_responseExpediente += `&subetapa=${_subEtapa}&rol=true`;
        url_responseContratacion += `&subetapa=${_subEtapa}&rol=true`;
        url_responseCliente += `&subetapa=${_subEtapa}&rol=true`;
      }

      if (_estatus) {
        url_responseAutorizacion += `&estatus=${_estatus}&rol=true`;
        url_responseExpediente += `&estatus=${_estatus}&rol=true`;
        url_responseContratacion += `&estatus=${_estatus}&rol=true`;
        url_responseCliente += `&estatus=${_estatus}&rol=true`;
      } else {
        url_responseAutorizacion += `&estatus=Pendiente&estatus=En proceso&estatus=Devuelta&rol=true`;
        url_responseExpediente += `&estatus=Pendiente&estatus=En proceso&estatus=Devuelta&rol=true`;
        url_responseContratacion += `&estatus=Pendiente&estatus=En proceso&estatus=Devuelta&rol=true`;
        url_responseCliente += `&estatus=Finalizada&rol=true`;
      }

      let results = await Promise.all([
        axiosInstance.get(url_responseAutorizacion),
        axiosInstance.get(url_responseExpediente),
        axiosInstance.get(url_responseContratacion),
        axiosInstance.get(url_responseCliente),
      ]);

      // obtener las respuestas
      let solicitudes_autorizacion = results[0].data.solicitudes;
      let solicitudes_expediente = results[1].data.solicitudes;
      let solicitudes_contratacion = results[2].data.solicitudes;
      let solicitudes_cliente = results[3].data.solicitudes;

      // mapear las solicitudes para validar si son editables
      solicitudes_autorizacion = validar_solicitudes_editables(
        solicitudes_autorizacion,
      );
      solicitudes_expediente = validar_solicitudes_editables(
        solicitudes_expediente,
      );
      solicitudes_contratacion = validar_solicitudes_editables(
        solicitudes_contratacion,
      );
      solicitudes_cliente = validar_solicitudes_editables(solicitudes_cliente);

      // ordenar por numero de solicitud
      solicitudes_autorizacion.sort((a, b) => a.numsolicitud - b.numsolicitud);
      solicitudes_expediente.sort((a, b) => a.numsolicitud - b.numsolicitud);
      solicitudes_contratacion.sort((a, b) => a.numsolicitud - b.numsolicitud);
      solicitudes_cliente.sort((a, b) => a.numsolicitud - b.numsolicitud);

      // asignar los datos al state
      setShowBotonLimpiar(tiene_filtros);
      setTableAutorizacionData(solicitudes_autorizacion);
      setTableExpedienteData(solicitudes_expediente);
      setTableContratacionData(solicitudes_contratacion);
      setTableClienteData(solicitudes_cliente);
    } catch (err) {
      console.log('Error al consultar las solicitudes', err);
      if (err && err.response && err.response.status == 503) {
        console.log('status', err.response.status);
      } else {
        toast.error('Error al consultar las solicitudes');
        console.log('Error al consultar las solicitudes', err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const filtrarData = data => {
    if (numeroSolicitud) {
      data = data.filter(item => {
        if (filterString(item.numsolicitud, numeroSolicitud)) {
          return true;
        }
        if (filterString(item.nombrecliente, numeroSolicitud)) {
          return true;
        }
        return false;
      });
    }
    return data;
  };

  const handleLimpiar = () => {
    setEtapa('');
    setSubetapa('');
    setEstatus('');
    setFechaInicio(moment().startOf('year').toDate());
    setFechaFin(moment().toDate());
    fetchData(null, null, null, null);
  };

  const handleBuscar = () => {
    fetchData(fechaInicio, fechaFin, subEtapa, estatus, etapa);
  };

  const validarInputFecha = event => {
    if (event.target.value) {
      let parts = event.target.value.split('-').map(item => item.trim());

      if (parts.length == 2) {
        let fecha_i = moment(parts[0], 'DD/MM/YYYY');
        let fecha_f = moment(parts[1], 'DD/MM/YYYY');
        let fecha_min = moment(fechaMin);
        let fecha_max = moment(fechaMax);

        // validar que las 2 fechas sean validas y que la fecha de inicio se menor a la final
        if (
          fecha_i.isValid() &&
          fecha_f.isValid() &&
          fecha_i.isSameOrBefore(fecha_f)
        ) {
          // validar fecha inicio y final
          if (
            fecha_i.isSameOrAfter(fecha_min) &&
            fecha_f.isSameOrBefore(fecha_max.endOf('day'))
          ) {
            setFechaInicio(fecha_i.toDate());
            setFechaFin(fecha_f.toDate());
            return;
          }
        }
      }

      setFechaInicio(fechaInicioAnio.toDate());
      setFechaFin(fechaActual.toDate());
    }
  };
  useEffect(() => {
    fetchCatalogos();
    fetchData();
  }, []);

  return (
    <>
      <div>
        <Title text="Asesor / Teamleader en Alianzas" />
        <div className="p-3 searchg">
          <Col md={5} className="mobileSearch">
            <Card className="search-style button-search-style">
              <InputGroup className="button-search-style">
                <InputGroupAddon
                  addonType="append"
                  className="button-filter-style"
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  <Button color="secondary" className="button-filter-style">
                    <MdFilterList />
                  </Button>
                </InputGroupAddon>
                <Input
                  placeholder="Número de solicitud"
                  className="inputplaceh"
                  value={numeroSolicitud}
                  onChange={e => setNumeroSolicitud(e.target.value)}
                />

                <InputGroupAddon
                  addonType="append"
                  className="button-search-style"
                >
                  <Button
                    color="secondary"
                    className="button-search-style"
                    onClick={() => handleBuscar()}
                  >
                    <MdSearch />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Card>
          </Col>
        </div>

        {show && (
          <div className="styleMob">
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <FormGroup>
                  <Label>Rango de fecha:</Label>
                  <DatePicker
                    locale={es}
                    selectsRange={true}
                    startDate={fechaInicio}
                    endDate={fechaFin}
                    minDate={fechaMin}
                    maxDate={fechaMax}
                    placeholderText="DD/MM/YYYY - DD/MM/YYYY"
                    dateFormat="dd/MM/yyyy"
                    showMonthYearDropdown
                    scrollableMonthYearDropdown
                    onChange={update => {
                      setFechaInicio(update[0]);
                      setFechaFin(update[1]);
                    }}
                    onChangeRaw={validarInputFecha}
                    className="react-datepicker__input dateStyle inputMobile"
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <FormGroup>
                  <label className="dateInit-r">Etapa:</label>
                  <Input
                    type="select"
                    name="select"
                    className="select-arrows dateStyle inputMobile"
                    value={etapa}
                    onChange={event => setEtapa(event.target.value)}
                  >
                    <option value="">Selecciona</option>
                    {tableEtapaData.map((etapa, index) => {
                      return <option key={index}>{etapa.descripcion}</option>;
                    })}
                  </Input>
                </FormGroup>
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <FormGroup>
                  <label className="dateInit-r">Subetapa:</label>
                  <Input
                    type="select"
                    name="select"
                    className="select-arrows inputMobile"
                    value={subEtapa}
                    onChange={event => setSubetapa(event.target.value)}
                  >
                    <option value="">Selecciona</option>
                    {tablesubEtapaData.map((subetapa, index) => {
                      return (
                        <option key={index}>{subetapa.descripcion}</option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <FormGroup>
                  <label className="dateInit-r comboStyle">Estatus:</label>
                  <Input
                    type="select"
                    name="select"
                    className="select-arrows inputMobile"
                    value={estatus}
                    onChange={event => setEstatus(event.target.value)}
                  >
                    <option value="">Selecciona</option>
                    {tablestatusData.map((status, index) => {
                      return <option key={index}>{status.descripcion}</option>;
                    })}
                  </Input>
                </FormGroup>
              </Col>

              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="botones_busqueda"
              >
                <Button
                  type="button"
                  onClick={handleBuscar}
                  disabled={isLoading}
                >
                  Buscar
                </Button>{' '}
                {showBotonLimpiar && (
                  <Button
                    type="button"
                    onClick={handleLimpiar}
                    disabled={isLoading}
                    style={{ marginLeft: 15 }}
                  >
                    Borrar filtros
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        )}
      </div>
      <TableSticky
        tableAutorizacionData={filtrarData(tableAutorizacionData)}
        tableExpedienteData={filtrarData(tableExpedienteData)}
        tableContratacionData={filtrarData(tableContratacionData)}
        tableClienteData={filtrarData(tableClienteData)}
        setTableAutorizacionData={setTableAutorizacionData}
        setTableExpedienteData={setTableExpedienteData}
        setTableContratacionData={setTableContratacionData}
        setTableClienteData={setTableClienteData}
        etapaPrincipal={etapaPrincipal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        catalogoPerdidaInteres={catalogoPerdidaInteres}
        catalogoRechazo={catalogoRechazo}
        fetchData={fetchData}
      />
    </>
  );
};
