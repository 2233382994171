import React, { useEffect, useState } from 'react';
import { ModalBody, Button, Row, Col } from 'reactstrap';
import toast from 'react-hot-toast';
import axiosInstance from '../../../../utils/axiosInstance';
import PreguntaInput from './PreguntaInput';
import Loading from '../../loading/Loading';
import moment from 'moment';

const ModalFormularioAcciones = ({
  handleClose,
  solicitud,
  informacionnumSolicitud,
}) => {
  const [preguntas, setPreguntas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let result = await axiosInstance.get(
        `/acciones/consulta_siguiente?id_solicitud=${solicitud.numsolicitud}&id_workflow=1`,
      );

      if (
        result.data.acciones &&
        result.data.acciones[0] &&
        result.data.acciones[0].detalle &&
        result.data.acciones[0].detalle.preguntas
      ) {
        setPreguntas(result.data.acciones[0].detalle.preguntas || []);
      }
    } catch (err) {
      //toast.error('Error al consultar las acciones');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGuardar = async () => {
    try {
      setIsSaving(true);

      let preguntas_sin_responder = preguntas.filter(item => {
        return (
          item.respuesta_usuario === undefined ||
          item.respuesta_usuario == null ||
          item.respuesta_usuario === ''
        );
      });

      if (preguntas_sin_responder.length) {
        toast('Completa el formulario para continuar');
        return;
      }

      let data = {
        idworkflow: 1,
        fechaactualizacion: moment().toDate(),
        numsolicitud: solicitud.numsolicitud,
        idsubetapa: +solicitud.idsubetapa,
        detalle: {
          preguntas: preguntas,
        },
      };

      await axiosInstance.put(`/acciones`, data);

      toast.success('Acciones guardadas correctamente');

      handleClose();
    } catch (err) {
      toast.error('Error al guardar');
    } finally {
      setIsSaving(false);
    }
  };

  const handleResponder = async (index, newValue) => {
    let copy = [...preguntas];
    if (copy[index]) {
      copy[index].respuesta_usuario = newValue;
    }
    setPreguntas(copy);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="popupcontent modal fade show">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <span className="titleHeader">Acciones</span>
              <div>
                <button type="button" className="close" aria-label="Close">
                  <span aria-hidden="true" onClick={handleClose}>
                    ×
                  </span>
                </button>
              </div>
            </div>
            <ModalBody className="modal_custom">
              {isLoading && <Loading style={{ display: 'flex' }} />}

              {!isLoading && preguntas.length === 0 && (
                <span>No se han encontrado preguntas</span>
              )}

              {preguntas.map((pregunta, index) => (
                <PreguntaInput
                  key={index}
                  tipo={pregunta.tipo_pregunta}
                  label={pregunta.texto_pregunta}
                  required={pregunta.requerido}
                  opciones={pregunta.opciones}
                  value={pregunta.respuesta_usuario}
                  onChange={value => handleResponder(index, value)}
                />
              ))}

              {!!preguntas.length && (
                <Row>
                  <Col md={12} className="text-right">
                    <Button
                      type="button"
                      onClick={handleClose}
                      className="btn-secondary"
                      disabled={isSaving}
                    >
                      Cancelar
                    </Button>{' '}
                    <Button
                      type="button"
                      onClick={handleGuardar}
                      disabled={isSaving}
                    >
                      Guardar
                    </Button>
                  </Col>
                </Row>
              )}
            </ModalBody>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalFormularioAcciones;
