import React, { useState, useEffect } from 'react';
import { Card, Col, Table, Row } from 'reactstrap';
import axiosInstance from '../../../../../../utils/axiosInstance';
import { dateFormat } from '../../../../../Helpers';
import ReactExport from 'react-data-export';
import imageexcel from '../../../../../../assets/img/icons/imageexcel.PNG';

export const Tables = ({ idSolicitud }) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    conexion();
  }, []);

  const conexion = async () => {
    try {
      let result = await axiosInstance.get(
        `acciones/consulta_historico?id_solicitud=${idSolicitud}&id_workflow=1&=`,
      );
      let elementos = result.data.acciones;
      setData(elementos);
    } catch {
      setMessage('No tiene data');
    }
  };
  console.log(data);
  return (
    <>
      <div className="input-date displayFlex">
        <div className="col-md-6">
          <p>
            Número Solicitud : <b>{`${idSolicitud}`}</b>
          </p>
        </div>

        <div className="col-md-6 text-end">
          {data.length > 0 && (
            <ExcelFile
              filename="Reporte de Número Solicitud"
              element={
                <button className="buttonexport mobilemarginT22">
                  <img src={imageexcel} height="32" />
                </button>
              }
            >
              <ExcelSheet data={data} name="Reporte de Número Solicitud">
                <ExcelColumn label="Número de Solicitud	" value="id" />
                <ExcelColumn label="Id Workflow" value="idworkflow" />
                <ExcelColumn label="Id Subetapa" value="idsubetapa" />
                <ExcelColumn label="Accion" value="accion" />
                <ExcelColumn label="Usuario Asignado" value="usuarioasignado" />
                <ExcelColumn
                  label="Fecha de Creacion"
                  value={col =>
                    col.fechacreacion === null
                      ? ''
                      : dateFormat(col.fechacreacion)
                  }
                />
                <ExcelColumn
                  label="Fecha de Fin	"
                  value={col =>
                    col.fechafin === null ? '' : dateFormat(col.fechafin)
                  }
                />
                <ExcelColumn label="Estatus" value="estatus" />
                <ExcelColumn
                  label="Pregunta 1"
                  value={col =>
                    col.detalle === null
                      ? ''
                      : col.detalle.preguntas[0].texto_pregunta
                  }
                />
                <ExcelColumn
                  label="Respuesta 1"
                  value={col =>
                    col.detalle === null
                      ? ''
                      : col.detalle.preguntas[0] === undefined
                      ? ''
                      : col.detalle.preguntas[0].respuesta_usuario
                  }
                />

                <ExcelColumn
                  label="Pregunta 2"
                  value={col =>
                    col.detalle === null
                      ? ''
                      : col.detalle.preguntas[1] === undefined
                      ? ''
                      : col.detalle.preguntas[1].texto_pregunta
                  }
                />

                <ExcelColumn
                  label="Respuesta 2"
                  value={col =>
                    col.detalle === null
                      ? ''
                      : col.detalle.preguntas[1] === undefined
                      ? ''
                      : col.detalle.preguntas[1].respuesta_usuario
                  }
                />
                <ExcelColumn
                  label="Pregunta 3"
                  value={col =>
                    col.detalle === null
                      ? ''
                      : col.detalle.preguntas[2] === undefined
                      ? ''
                      : col.detalle.preguntas[2].texto_pregunta
                  }
                />
                <ExcelColumn
                  label="Respuesta 3"
                  value={col =>
                    col.detalle === null
                      ? ''
                      : col.detalle.preguntas[2] === undefined
                      ? ''
                      : col.detalle.preguntas[2].respuesta_usuario
                  }
                />

                <ExcelColumn
                  label="Pregunta 4"
                  value={col =>
                    col.detalle === null
                      ? ''
                      : col.detalle.preguntas[3] === undefined
                      ? ''
                      : col.detalle.preguntas[3].texto_pregunta
                  }
                />

                <ExcelColumn
                  label="Respuesta 4"
                  value={col =>
                    col.detalle === null
                      ? ''
                      : col.detalle.preguntas[3] === undefined
                      ? ''
                      : col.detalle.preguntas[3].respuesta_usuario
                  }
                />
                <ExcelColumn
                  label="Pregunta 5"
                  value={col =>
                    col.detalle === null
                      ? ''
                      : col.detalle.preguntas[4] === undefined
                      ? ''
                      : col.detalle.preguntas[4].texto_pregunta
                  }
                />

                <ExcelColumn
                  label="Respuesta 5"
                  value={col =>
                    col.detalle === null
                      ? ''
                      : col.detalle.preguntas[4] === undefined
                      ? ''
                      : col.detalle.preguntas[4].respuesta_usuario
                  }
                />
              </ExcelSheet>
            </ExcelFile>
          )}
        </div>
      </div>
      <div className="scrollSticky">
        <Row className="p-3 marginLR0">
          <Col>
            <Card className="mb-3 backgroundTransparent">
              <Table
                responsive
                className="tableSticky border2xWhite tableScrollNone marginT16"
              >
                <thead>
                  <tr>
                    <th>Número de Solicitud</th>
                    <th>Id Workflow</th>
                    <th>Id Subetapa</th>
                    <th>Accion</th>
                    <th>Usuario Asignado</th>
                    <th>Fecha de Creacion</th>
                    <th>Fecha de Fin</th>
                    <th>Estatus</th>
                    <th>Pregunta 1</th>
                    <th>Respuesta 1</th>
                    <th>Pregunta 2</th>
                    <th>Respuesta 2</th>
                    <th>Pregunta 3</th>
                    <th>Respuesta 3</th>
                    <th>Pregunta 4</th>
                    <th>Respuesta 4</th>
                    <th>Pregunta 5</th>
                    <th>Respuesta 5</th>
                  </tr>
                </thead>
                <tbody>
                  {message !== '' && (
                    <>
                      <tr>
                        <td colspan="8">{message}</td>
                      </tr>
                    </>
                  )}
                  {data.length === 0 && message === '' ? (
                    <>
                      <tr>
                        <td colspan="8">
                          <div className="text-center">
                            <div className="lds-ring">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    data.map(solicitud => (
                      <tr>
                        <td>{solicitud.numsolicitud}</td>
                        <td>{solicitud.idworkflow}</td>
                        <td>{solicitud.idsubetapa}</td>
                        <td>{solicitud.accion}</td>
                        <td>{solicitud.usuarioasignado}</td>
                        <td>{dateFormat(solicitud.fechacreacion)}</td>
                        <td>{dateFormat(solicitud.fechafin)}</td>
                        <td>{solicitud.estatus}</td>
                        {solicitud.detalle.preguntas.map(pregunta => (
                          <>
                            <td>
                              {pregunta.texto_pregunta.length === 0
                                ? ''
                                : pregunta.texto_pregunta}
                            </td>
                            <td>
                              {pregunta.respuesta_usuario === ''
                                ? ''
                                : pregunta.respuesta_usuario}
                            </td>
                          </>
                        ))}
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
