import logo from '../../assets/img/logo/logo-blanco.png';
import sidebarBgImage from '../../assets/img/sidebar/sidebar-4.jpg';
import SourceLink from '../Moleculas/generic/SourceLink';
import fernet from 'fernet';
import React, { useState, useEffect } from 'react';
import { getScreenWidth, logOut, getCookiesValue } from '../Helpers/index';
import {
  MdClose,
  MdAccountCircle,
  MdSearch,
  MdEqualizer,
  MdKeyboardArrowDown,
  MdBorderAll,
  MdPermContactCalendar,
  MdViewList,
  MdPersonOutline,
  MdPlaylistAddCheck,
} from 'react-icons/md';
import { NavLink, withRouter } from 'react-router-dom';
import { tokenSecret } from '../../constants';
import {
  Collapse,
  Nav,
  Navbar,
  NavItem,
  Button,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';

const sidebarBackground = {
  background: '#212529',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navContents = [
  {
    to: '/asesor-team-leader-en-alianzas',
    name: 'Asesor / Teamleader en Alianzas',
    exact: false,
    Icon: MdPersonOutline,
  },
  {
    to: '/area-de-onboarding',
    name: 'Área de Onboarding',
    exact: false,
    Icon: MdPlaylistAddCheck,
  },
  {
    to: '/area-de-dictaminacion',
    name: 'Área de Dictaminación',
    exact: false,
    Icon: MdSearch,
  },
  {
    to: '/mesa-de-credito',
    name: 'Mesa de Crédito',
    exact: false,
    Icon: MdPlaylistAddCheck,
  },
  {
    to: '/relationship-managers',
    name: 'Relationship Managers',
    exact: false,
    Icon: MdEqualizer,
  },
  {
    to: '/mesa-de-control',
    name: 'Mesa de Control',
    exact: false,
    Icon: MdEqualizer,
  },
  { to: '/tesoreria', name: 'Tesorería', exact: false, Icon: MdSearch },
  { to: '/cobranza', name: 'Cobranza', exact: false, Icon: MdPersonOutline },
];

const navContentsAsesor = [
  {
    to: '/asesor-team-leader-en-alianzas',
    name: 'Asesor / Teamleader en Alianzas',
    exact: false,
    Icon: MdPersonOutline,
  },
];

const navContentsMesadeControl = [
  {
    to: '/mesa-de-control',
    name: 'Mesa de Control',
    exact: false,
    Icon: MdEqualizer,
  },
];

const navContentsOnboarding = [
  {
    to: '/area-de-onboarding',
    name: 'Área de Onboarding',
    exact: false,
    Icon: MdPlaylistAddCheck,
  },
];

const navContentsDictaminacion = [
  {
    to: '/area-de-dictaminacion',
    name: 'Área de Dictaminación',
    exact: false,
    Icon: MdSearch,
  },
];

const navContentsMesadecredito = [
  {
    to: '/mesa-de-credito',
    name: 'Mesa de Crédito',
    exact: false,
    Icon: MdPlaylistAddCheck,
  },
];

const navContentsRelationship = [
  {
    to: '/relationship-managers',
    name: 'Relationship Managers',
    exact: false,
    Icon: MdEqualizer,
  },
];

const navContentsTesoreria = [
  { to: '/tesoreria', name: 'Tesorería', exact: false, Icon: MdSearch },
];

const navContentsCobranza = [
  { to: '/cobranza', name: 'Cobranza', exact: false, Icon: MdPersonOutline },
];

const navReportes = [
  {
    to: '/reporte-solicitudes',
    name: 'Solicitudes',
    exact: false,
    Icon: MdPermContactCalendar,
  },
];

const navSesion = [
  { to: '/login', name: 'Cerrar Sesión', exact: false, Icon: MdAccountCircle },
];

const navGestionSesion = [
  {
    to: '/gestion-de-usuarios',
    name: 'Gestión de usuarios',
    exact: false,
    Icon: MdViewList,
  },
];

const bem = bn.create('sidebar');

const Sidebar = props => {
  const [descode, setDescode] = useState('');
  const [correo, setCorreo] = useState('');
  const [state, setState] = useState({
    isOpenComponents: false,
    isOpenContents: false,
    isOpenReportes: false,
    isOpenPages: false,
    isOpenGestionSesion: false,
  });

  const handleClick = name => () => {
    const isOpen = state[`isOpen${name}`];
    setState({
      ...state,
      [`isOpen${name}`]: !isOpen,
    });
  };

  const handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  const validarTokens = () => {
    let cookiesAcces = getCookiesValue('accessuser');

    function leerCookie(nombre) {
      var lista = document.cookie.split(';');
      var i = 0;
      for (i in lista) {
        var busca = lista[i].search(nombre);
        if (busca > -1) {
          var micookie = lista[i];
        }
      }
      var igual = micookie.indexOf('=');
      var valor = micookie.substring(igual + 1);
      return valor;
    }
    let correoValue = leerCookie('user');
    if (!cookiesAcces) {
      return;
    }

    let secret = new fernet.Secret(`${tokenSecret}`);
    let token = new fernet.Token({
      secret: secret,
      token:
        'gAAAAABSO_yhAAECAwQFBgcICQoLDA0OD1PGoFV6wgWZG6AOBfQqevwJT2qKtCZ0EjKy1_TvyxTseR_3ebIF6Ph-xa2QT_tEvg==',
      ttl: 0,
    });
    let resultado = token.decode(cookiesAcces);
    let correo = token.decode(correoValue);

    setCorreo(correo);
    setDescode(resultado);
  };

  useEffect(() => {
    validarTokens();

    const typeSreen = getScreenWidth();
    if (typeSreen === 'xs' || typeSreen === 'sm' || typeSreen === 'md') {
      var elements = document.getElementsByClassName('nav-mobile');
      var functionClick = function () {
        document
          .querySelector('.cr-sidebar')
          .classList.toggle('cr-sidebar--open');
      };
      for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener('click', functionClick, false);
      }
    }
  }, []);

  const handleLogout = async () => {
    try {
      logOut(props);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => { }, []);

  return (
    <aside className={bem.b()} data-image={sidebarBgImage}>
      <div className={bem.e('background')} style={sidebarBackground} />
      <div className={bem.e('content')}>
        <div className="divBut">
          <Button
            outline
            onClick={handleSidebarControlButton}
            className="buttonNavBar"
          >
            <MdClose size={25} />
          </Button>
        </div>
        <Navbar>
          <SourceLink className="navbar-brand d-flex">
            <img
              src={logo}
              width="188"
              height="auto"
              className="pr-2 imgLogo"
              alt=""
            />
          </SourceLink>
        </Navbar>
        <NavItem className={(bem.e('nav-item'), 'nav-correo')}>
          <button className="buttonlogout text-bold">{correo} </button>
        </NavItem>
        <Nav vertical>
          <>
            {descode === 'Área de Dictaminación' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={handleClick('Contents')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdViewList className={bem.e('nav-item-icon')} />
                      <span className="">Flujos </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: state.isOpenContents
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={state.isOpenContents}>
                  {navContentsDictaminacion.map(
                    ({ to, name, exact, Icon }, index) => (
                      <NavItem
                        key={index}
                        className={(bem.e('nav-item'), 'nav-mobile')}
                      >
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          className="text-capitalize"
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                        >
                          <Icon className={bem.e('nav-item-icon')} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ),
                  )}
                </Collapse>
              </>
            )}
            {descode === 'Área de Onboarding' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={handleClick('Contents')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdViewList className={bem.e('nav-item-icon')} />
                      <span className="">Flujos </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: state.isOpenContents
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={state.isOpenContents}>
                  {navContentsOnboarding.map(
                    ({ to, name, exact, Icon }, index) => (
                      <NavItem
                        key={index}
                        className={(bem.e('nav-item'), 'nav-mobile')}
                      >
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          className="text-capitalize"
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                        >
                          <Icon className={bem.e('nav-item-icon')} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ),
                  )}
                </Collapse>
              </>
            )}

            {descode === 'Mesa de Crédito' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={handleClick('Contents')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdViewList className={bem.e('nav-item-icon')} />
                      <span className="">Flujos </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: state.isOpenContents
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={state.isOpenContents}>
                  {navContentsMesadecredito.map(
                    ({ to, name, exact, Icon }, index) => (
                      <NavItem
                        key={index}
                        className={(bem.e('nav-item'), 'nav-mobile')}
                      >
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          className="text-capitalize"
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                        >
                          <Icon className={bem.e('nav-item-icon')} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ),
                  )}
                </Collapse>
              </>
            )}
            {descode === 'Relationship Managers' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={handleClick('Contents')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdViewList className={bem.e('nav-item-icon')} />
                      <span className="">Flujos </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: state.isOpenContents
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={state.isOpenContents}>
                  {navContentsRelationship.map(
                    ({ to, name, exact, Icon }, index) => (
                      <NavItem
                        key={index}
                        className={(bem.e('nav-item'), 'nav-mobile')}
                      >
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          className="text-capitalize"
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                        >
                          <Icon className={bem.e('nav-item-icon')} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ),
                  )}
                </Collapse>
              </>
            )}

            {descode === 'Tesorería' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={handleClick('Contents')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdViewList className={bem.e('nav-item-icon')} />
                      <span className="">Flujos </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: state.isOpenContents
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={state.isOpenContents}>
                  {navContentsTesoreria.map(
                    ({ to, name, exact, Icon }, index) => (
                      <NavItem
                        key={index}
                        className={(bem.e('nav-item'), 'nav-mobile')}
                      >
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          className="text-capitalize"
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                        >
                          <Icon className={bem.e('nav-item-icon')} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ),
                  )}
                </Collapse>
              </>
            )}
            {descode === 'Mesa de Control' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={handleClick('Contents')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdViewList className={bem.e('nav-item-icon')} />
                      <span className="">Flujos </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: state.isOpenContents
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={state.isOpenContents}>
                  {navContentsMesadeControl.map(
                    ({ to, name, exact, Icon }, index) => (
                      <NavItem
                        key={index}
                        className={(bem.e('nav-item'), 'nav-mobile')}
                      >
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          className="text-capitalize"
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                        >
                          <Icon className={bem.e('nav-item-icon')} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ),
                  )}
                </Collapse>
              </>
            )}

            {descode === 'Asesor / Teamleader en Alianzas' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={handleClick('Contents')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdViewList className={bem.e('nav-item-icon')} />
                      <span className="">Flujos </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: state.isOpenContents
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={state.isOpenContents}>
                  {navContentsAsesor.map(({ to, name, exact, Icon }, index) => (
                    <NavItem
                      key={index}
                      className={(bem.e('nav-item'), 'nav-mobile')}
                    >
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className="text-capitalize"
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </>
            )}

            {descode === 'admin' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={handleClick('Contents')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdViewList className={bem.e('nav-item-icon')} />
                      <span className="">Flujos </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: state.isOpenContents
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={state.isOpenContents}>
                  {navContents.map(({ to, name, exact, Icon }, index) => (
                    <NavItem
                      key={index}
                      className={(bem.e('nav-item'), 'nav-mobile')}
                    >
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className="text-capitalize"
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={handleClick('Reports')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdBorderAll className={bem.e('nav-item-icon')} />
                      <span className="">Reportes </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: state.isOpenReports
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={state.isOpenReports}>
                  {navReportes.map(({ to, name, exact, Icon }, index) => (
                    <NavItem
                      key={index}
                      className={(bem.e('nav-item'), 'nav-mobile')}
                    >
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className="text-capitalize"
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={handleClick('GestionSesion')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdPersonOutline className={bem.e('nav-item-icon')} />
                      <span className="">Usuarios</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: state.isOpenGestionSesion
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
              </>
            )}

            {descode === 'Cobranza' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={handleClick('Contents')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdViewList className={bem.e('nav-item-icon')} />
                      <span className="">Flujos </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: state.isOpenContents
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={state.isOpenContents}>
                  {navContentsCobranza.map(
                    ({ to, name, exact, Icon }, index) => (
                      <NavItem
                        key={index}
                        className={(bem.e('nav-item'), 'nav-mobile')}
                      >
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          className="text-capitalize"
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                        >
                          <Icon className={bem.e('nav-item-icon')} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ),
                  )}
                </Collapse>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={handleClick('Reports')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdBorderAll className={bem.e('nav-item-icon')} />
                      <span className="">Reportes </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: state.isOpenReports
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={state.isOpenReports}>
                  {navReportes.map(({ to, name, exact, Icon }, index) => (
                    <NavItem
                      key={index}
                      className={(bem.e('nav-item'), 'nav-mobile')}
                    >
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className="text-capitalize"
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </>
            )}

            {descode === 'Admin TI' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={handleClick('GestionSesion')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdPersonOutline className={bem.e('nav-item-icon')} />
                      <span className="">Usuarios</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: state.isOpenGestionSesion
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
              </>
            )}

            <Collapse isOpen={state.isOpenGestionSesion}>
              {navGestionSesion.map(({ to, name, exact, Icon }, index) => (
                <NavItem
                  key={index}
                  className={(bem.e('nav-item'), 'nav-mobile')}
                >
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-capitalize"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
          </>
          {navSesion.map(({ to, name, exact, Icon }, index) => (
            <NavItem
              key={index}
              className={(bem.e('nav-item'), 'nav-cerrar-sesion')}
            >
              <Icon className={bem.e('nav-item-icon')} />
              <button onClick={handleLogout} className="buttonlogout dashlog">
                {name}{' '}
              </button>
            </NavItem>
          ))}
        </Nav>
      </div>
    </aside>
  );
};

export default withRouter(Sidebar);
