import React from 'react'
import { Toaster } from 'react-hot-toast';

const CustomToast = ({ children }) => {
    return (
        <Toaster
            position="top-center"
            toastOptions={{
                duration: 3000,
                style: {
                    fontSize: 14,
                    background: '#FF9966',
                    color: '#fff',
                    width: 400,
                    minHeight: 60
                },
                success: {
                    style: {
                        backgroundColor: 'green'
                    }
                },
                error: {
                    style: {
                        backgroundColor: 'red'
                    }
                },
            }}
        />
    )
}

export default CustomToast;