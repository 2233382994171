import React from 'react';
import { FormLogin } from './Layout/Login/formLogin';
import loginimage from '../assets/img/login/joven-mujer-uniclic.png';
import './Layout/Login/style.css'
import { isLogin, setLoginToken } from '../utils/isLogin'
import { Redirect } from 'react-router-dom';

export const Login = () => {

  if (isLogin()) {
    return (<Redirect to="/" />)
  }

  return (
    <>
      <div class="back">
        <main class="main bod">
          <div class="col-md-7 loginsrc text-center">
            <img src={loginimage} class="imglogin" alt="imglogin" />
          </div>
          <div class="col-md-4 tblt">
            <h1 class="titleLogin">Pipeline de Ventas</h1>
            <FormLogin />
          </div>
        </main>
      </div>
    </>
  );
};
