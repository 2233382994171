import moment from 'moment';

export const getScreenWidth = () => {
  let screen = '';
  switch (window && typeof window.matchMedia !== 'undefined') {
    case window.matchMedia('(max-width: 768px)').matches:
      screen = 'xs';
      //mobile
      break;
    case window.matchMedia('(min-width:768px) and (max-width: 991px)').matches:
      screen = 'sm';
      //mobile
      break;
    case window.matchMedia('(min-width:992px) and (max-width: 1231px)').matches:
      screen = 'md';
      //tablet
      break;
    case window.matchMedia('(min-width: 1232px)').matches:
      screen = 'lg';
      //web
      break;
    default:
      break;
  }
  return screen;
};

export function callcookie(name, value, days) {
  if (days) {
    var d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = '; expires=' + d.toGMTString();
  } else var expires = '';
  document.cookie = name + '=' + value + expires + '; path=/';
}

export function getCookiesObject(key) {
  let value = getCookiesValue(key);
  return value ? JSON.parse(decodeURIComponent(value)) : undefined;
}

export function getCookiesValue(key) {
  let cookieValue = window.document.cookie.match(key + '=[^ ;]*');

  if (cookieValue) {
    return cookieValue[0].replace(key + '=', '');
  } else {
    return '';
  }
}

export function deleteAllCookies() {
  var cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf('=');
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

export const dateFormat = dateFormat => {
  if (moment(dateFormat).isValid()) {
    return moment(dateFormat).format('DD/MM/YYYY');
  }
  return '';
};
export function eliminarCookies() {
  document.cookie.split(';').forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });
}
export function logOut(props) {
  eliminarCookies('accesstoken');
  eliminarCookies('accessuser');

  if (props && props.history) {
    props.history.push('/login');
  } else {
    window.location.href = '/login';
    window.location.reload();
  }
}
