import React, { useState, useEffect } from 'react';
import { ModalBody, FormGroup, Input, Label, Col } from 'reactstrap';
import axiosInstance from '../../../../utils/axiosInstance';
import Loading from '../../loading/Loading';
import toast from 'react-hot-toast';

export const ModalCodigodeOrigen = ({ solicitud, handleModalClose }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [dataSolicitud, setDataSolicitud] = useState([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let result = await axiosInstance(`/solicitudes/consulta_rol?id_solicitud=${solicitud.numsolicitud}&id_workflow=1&campo=detalleorigen`);
      if (result.data.detalleorigen) {
        let solicitud_data = [];
        result.data.detalleorigen.forEach(item1 => {
          Object.entries(item1).forEach(item2 => {
            solicitud_data.push(item2);
          });
        });
        setDataSolicitud(solicitud_data);
      }
    } catch (err) {
      console.log(err);
      toast.error('Error al consultar la solicitud');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="popupcontent modal fade show">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <span className="titleHeader">Datos de la solicitud</span>
              <div>
                <button type="button" className="close" aria-label="Close">
                  <span aria-hidden="true" onClick={handleModalClose}>
                    ×
                  </span>
                </button>
              </div>
            </div>
            <ModalBody className="modal_custom">

              {isLoading &&
                <Loading style={{ display: 'flex' }} />
              }

              {!isLoading &&
                <>
                  {(!dataSolicitud || dataSolicitud.length === 0) &&
                    <span>No hay información disponible</span>
                  }

                  {dataSolicitud && dataSolicitud.map((item, index) => (
                    <FormGroup row key={index}>
                      <Label sm={4}>{item[0]}</Label>
                      <Col sm={8}>
                        <Input
                          type="text"
                          disabled
                          value={item[1]}
                        />
                      </Col>
                    </FormGroup>
                  ))}
                </>
              }
            </ModalBody>
          </div>
        </div>
      </div>
    </>
  );
};