import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import toast from 'react-hot-toast';
import axiosInstance from '../../../utils/axiosInstance';

export const FechaValidacionDatePicker = ({ solicitud, value }) => {
  const [startDate, setStartDate] = useState('');
  const fechaMin = moment().subtract(2, new Date()).toDate();

  const handleFechaValidacion = async date => {
    const fecha = moment(date).format('YYYY-MM-DD');
    try {
      let data = {
        numsolicitud: `${solicitud.numsolicitud}`,
        fechatentativa: `${fecha}`,
        idworkflow: 1,
      };
      await axiosInstance.put(`/solicitudes/actualizar_fecha_validacion`, data);
      toast.success('Fecha actualizada con exito');
    } catch (error) {
      console.log(error);
      toast.error('Error al ingresar la fecha de validacion');
    } finally {
    }
  };

  const fechaInicial =
    value === null ? 'dd/mm/yyyy' : moment(value).format('DD/MM/YYYY');

  return (
    <DatePicker
      selected={startDate}
      className="dateStyle react-datepicker__input_date"
      dateFormat="dd/MM/yyyy"
      onChange={date => {
        setStartDate(date);
        handleFechaValidacion(date);
      }}
      minDate={fechaMin}
      placeholderText={`${fechaInicial}`}
    />
  );
};
