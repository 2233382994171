import React, { useState, useEffect } from 'react';
import { MdSearch } from 'react-icons/md';
import { getCookiesValue } from '../../../../../components/Helpers';
import { apiRoot, tokenSecret } from '../../../../../constants';
import toast from 'react-hot-toast';
import {
  Card,
  Col,
  Table,
  Button,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
} from 'reactstrap';
import fernet from 'fernet';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import axiosInstance from '../../../../../utils/axiosInstance';

export const Tables = ({ usersList, message }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [users, setUser] = useState('');
  const [form, setForm] = useState('');
  const [active, setActive] = useState('');
  const [rolUser, setRolUser] = useState(false);
  const [userData, setUserData] = useState({
    correo: '',
    rol: '',
    password: '',
    password_confirm: '',
    estatususuario: '',
  });
  const [emailError, setEmailError] = useState('');

  const handleChange = event => {
    setForm(event.target.value);
  };

  const handleChangeState = event => {
    setActive(event.target.value);
  };
  const cookiesAcces = getCookiesValue('accesstoken');
  var myHeaders = new Headers();
  myHeaders.append('access_token', `${cookiesAcces}`);
  myHeaders.append('Content-Type', 'application/json');

  const fetchCatalogo = async () => {
    try {
      let result = await axiosInstance.get(
        `/catalogos?nombre_catalogo=catalogoroles`,
      );

      const data = result.data;
      setRolUser(data.elementos);
      setForm(data.elementos[0].descripcion);
    } catch (err) {
      toast.error('Error al obtener el catalogo de roles');
    } finally {
    }
  };

  const handleDelete = () => {
    var raw = JSON.stringify({
      correo: users,
    });

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(`${apiRoot}/accesos/registro`, requestOptions)
      .then(response => response.text())
      .then(result => {
        setShowDelete(false);
        toast.success('Usuario desactivo corretamente');

        setTimeout(() => {
          window.location.reload();
        }, 1200);
      })
      .catch(error => console.log('error', error));
  };
  useEffect(() => {
    fetchCatalogo();
  }, []);

  const handleModalCloseEdit = () => {
    setShowEdit(false);
    const clase13 = window.document.getElementsByClassName(
      'cr-content container-fluid',
    );
    for (var i = 0; i < clase13.length; i++) {
      clase13[i].removeAttribute('style');
    }
  };

  const handleModalCloseDelete = () => {
    setShowDelete(false);
    const clase13 = window.document.getElementsByClassName(
      'cr-content container-fluid',
    );
    for (var i = 0; i < clase13.length; i++) {
      clase13[i].removeAttribute('style');
    }
  };

  const handleEdit = () => {
    let password_exp = new RegExp(/^(?=.*?\d)(?=.*?[a-zA-Z_-])[a-zA-Z\d]+$/);

    if (showPassword) {
      if (
        !userData.password ||
        !userData.password_confirm ||
        !userData.password.length ||
        !userData.password_confirm.length
      ) {
        setEmailError('*Por favor debe llenar los datos');
        return;
      } else if (userData.password.length < 10) {
        setEmailError('*La contraseña debe ser mayor a 10 digitos');
        return;
      } else if (!password_exp.test(userData.password)) {
        setEmailError('*La contraseña debe contener numeros y letras');
        return;
      } else if (userData.password !== userData.password_confirm) {
        setEmailError('*Las contraseñas no coinciden');
        return;
      }

      let secret = new fernet.Secret(`${tokenSecret}`);
      let token = new fernet.Token({
        secret: secret,
        time: Date.parse(1),
        iv: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      });

      var raw = {
        correo: userData.correo,
        rol: userData.rol,
        estatususuario: userData.estatususuario,
        password: token.encode(userData.password),
      };

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(raw),
        redirect: 'follow',
      };

      fetch(`${apiRoot}/accesos/registro`, requestOptions)
        .then(response => response.text())
        .then(result => {
          setShowEdit(false);
          toast.success('Usuario editado corretamente');

          setTimeout(() => {
            window.location.reload();
          }, 1200);
        })
        .catch(error => console.log('error', error));
    } else {
      var raw1 = {
        correo: userData.correo,
        rol: userData.rol,
        estatususuario: userData.estatususuario,
      };

      var requestOptions1 = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(raw1),
        redirect: 'follow',
      };

      fetch(`${apiRoot}/accesos/registro`, requestOptions1)
        .then(response => response.text())
        .then(result => {
          setShowEdit(false);
          toast.success('Usuario editado corretamente');

          setTimeout(() => {
            window.location.reload();
          }, 1200);
        })
        .catch(error => console.log('error', error));
    }
  };
  const handleContrasena = () => {
    setShowPassword(!showPassword);
  };
  const handleClickEdit = user => {
    setUserData({
      correo: user.correo,
      rol: user.nombrerol,
      password: '',
      estatususuario: user.estatususuario,
    });
    setShowEdit(true);
    const clase13 = window.document.getElementsByClassName(
      'cr-content container-fluid',
    );
    for (var i = 0; i < clase13.length; i++) {
      clase13[i].setAttribute('style', 'max-height: initial');
    }
  };

  usersList.sort(function (a, b) {
    if (a.correo > b.correo) return 1;
    if (a.correo < b.correo) return -1;
    return -0;
  });

  return (
    <>
      <Col>
        <Card className="mb-3 backgroundTransparent">
          <Table
            responsive
            className="tableSticky border2xWhite tableScrollNone marginT16"
          >
            <thead className="stickyHead">
              <tr>
                <th>Correo</th>
                <th>Rol</th>
                <th>Estado de usuario</th>
                <th colspan="2">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {message.length > 0 && (
                <tr>
                  <td colspan="5">
                    <div className="containervacia">
                      <MdSearch className="busquedavacia" />
                      <p>{message}</p>
                    </div>
                  </td>
                </tr>
              )}

              {usersList.map(user => {
                const handleClickDelete = () => {
                  setUser(user.correo);
                  setShowDelete(true);
                  const clase13 = window.document.getElementsByClassName(
                    'cr-content container-fluid',
                  );
                  for (var i = 0; i < clase13.length; i++) {
                    clase13[i].setAttribute('style', 'max-height: initial');
                  }
                };

                return (
                  <>
                    <tr>
                      <td>{user.correo}</td>
                      <td>{user.nombrerol}</td>
                      <td>{user.estatususuario}</td>
                      <td>
                        <MdModeEdit onClick={() => handleClickEdit(user)} />
                      </td>
                      <td>
                        <MdDelete onClick={handleClickDelete} />
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </Card>
      </Col>

      {showEdit && (
        <div className="popupcontent modal fade show">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Editar Usuario</h5>
                <button type="button" className="close" aria-label="Close">
                  <span aria-hidden="true" onClick={handleModalCloseEdit}>
                    ×
                  </span>
                </button>
              </div>
              <ModalBody>
                {/* <pre>{JSON.stringify(userData, null, 2)}</pre> */}
                <Col xl={12} lg={12} md={12}>
                  <form onSubmit={handleEdit} autoComplete="nope">
                    <FormGroup row>
                      <Label for="exampleEmail" className="formStyle-us" sm={3}>
                        Correo
                      </Label>
                      <Col sm={9}>
                        <Input
                          placeholder="Correo"
                          type="email"
                          id="email"
                          name="email"
                          className="disabledinput"
                          value={userData.correo}
                          onChange={e =>
                            setUserData({ ...userData, email: e.target.value })
                          }
                          autoComplete="update-email"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        for="examplePassword"
                        className="formStyle-us"
                        sm={10}
                      >
                        ¿Desea cambiar de contraseña?
                      </Label>
                      <Col sm={2} className="checkboxpwd">
                        <Input type="checkbox" onClick={handleContrasena} />
                      </Col>
                    </FormGroup>
                    {showPassword && (
                      <>
                        <FormGroup row>
                          <Label
                            for="examplePassword"
                            className="formStyle-us"
                            sm={3}
                          >
                            Contraseña
                          </Label>
                          <Col sm={9}>
                            <Input
                              type="password"
                              onChange={e =>
                                setUserData({
                                  ...userData,
                                  password: e.target.value,
                                })
                              }
                              name="password"
                              id="password"
                              placeholder="Contraseña"
                              autoComplete="update-password"
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="examplePassword"
                            className="formStyle-us"
                            sm={3}
                          >
                            Confirmar Contraseña
                          </Label>
                          <Col sm={9}>
                            <Input
                              type="password"
                              onChange={e =>
                                setUserData({
                                  ...userData,
                                  password_confirm: e.target.value,
                                })
                              }
                              name="password_confirm"
                              id="password_confirm"
                              placeholder="Confirmar Contraseña"
                            />
                          </Col>
                        </FormGroup>
                      </>
                    )}

                    <FormGroup row>
                      <Label
                        for="examplePassword"
                        className="formStyle-us"
                        sm={3}
                      >
                        Rol
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="select"
                          onChange={e => {
                            setUserData({
                              ...userData,
                              rol: e.target.value,
                            });
                          }}
                          name="select"
                          className="selectStyle select-arrows inputMobile "
                        >
                          {rolUser ? (
                            rolUser.map((rol, index) => {
                              if (rol.descripcion === userData.rol) {
                                return (
                                  <option
                                    key={index}
                                    value={rol.descripcion}
                                    selected
                                  >
                                    {rol.descripcion}
                                  </option>
                                );
                              }

                              return (
                                <option key={index} value={rol.descripcion}>
                                  {rol.descripcion}
                                </option>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </Input>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        for="examplePassword"
                        className="formStyle-us"
                        sm={3}
                      >
                        Estado
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="select"
                          onChange={e => {
                            setUserData({
                              ...userData,
                              estatususuario: e.target.value,
                            });
                          }}
                          name="select"
                          className="selectStyle select-arrows inputMobile "
                        >
                          {userData.estatususuario === 'activo' ? (
                            <>
                              <option value="activo" selected>
                                activo
                              </option>
                              <option value="inactivo">inactivo</option>
                            </>
                          ) : (
                            <>
                              <option value="activo">activo</option>
                              <option value="inactivo" selected>
                                inactivo
                              </option>
                            </>
                          )}
                        </Input>
                      </Col>
                    </FormGroup>
                  </form>
                </Col>
                {!!emailError && (
                  <div className="divstyleAlertModal">
                    <label className="styleAlertmodal text-red">
                      {emailError}
                    </label>
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={handleModalCloseEdit}>
                  Cancelar
                </Button>
                <Button color="secondary" onClick={handleEdit}>
                  Guardar
                </Button>
              </ModalFooter>
            </div>
          </div>
        </div>
      )}

      {showDelete && (
        <div className="popupcontent modal fade show">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Desactivar Usuario</h5>
                <button type="button" className="close" aria-label="Close">
                  <span aria-hidden="true" onClick={handleModalCloseDelete}>
                    ×
                  </span>
                </button>
              </div>
              <ModalBody className="text-center">¿Estas seguro?</ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={handleModalCloseDelete}>
                  Cancelar
                </Button>
                <Button color="secondary" onClick={handleDelete}>
                  Guardar
                </Button>
              </ModalFooter>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
