import React, { useState } from 'react'
import {
    Col,
    Card,
    InputGroup,
    InputGroupAddon,
    Input,
    Row,
    Button,
} from 'reactstrap';
import {MdSearch,MdFilterList} from 'react-icons/md';
import './style.css';


export const Search = ({placeholder}) => {
    const [show, setShow] = useState(false);

    return (
    <>
        <div className="p-3 searchg">
            <Col md={5} className="mobileSearch">
                <Card className="search-style button-search-style">
                    <InputGroup  className="button-search-style">
                        <InputGroupAddon addonType="append" className="button-filter-style"   onClick={() => {setShow(!show);}}><Button color="secondary" className="button-filter-style"><MdFilterList/></Button></InputGroupAddon>
                        <Input placeholder={placeholder} className="inputplaceh" />
                
                        <InputGroupAddon addonType="append" className="button-search-style"><Button color="secondary" className="button-search-style"><MdSearch/></Button></InputGroupAddon>
                    </InputGroup>
                </Card>
            </Col>
        </div>
        {
            show && 
                    <div className='styleMob'>
                    <Row className='p-3 filtersMobile' >
                        <Col xl={12} lg={12} md={12} className='filters'>
                            <div className='input-date'>
                                <label className='dateInit-r'>Rango de fecha:</label>
                                <input type="date" className='dateStyle inputMobile marginLeft10'/>
                            </div>

                            <div className='input-date displayWebkitInlineBox'>
                            <label className='dateInit-r marginT8'>Status de Acción:</label>
                            <Input type="select" name="select" className="selectStyle select-arrows inputMobile marginLeft10 widthFC">
                                <option>Aprobar</option>
                                <option>Checkbokx</option>
                                <option>Comentar </option>
                            </Input>
                            </div>
                            <div className='input-date comboDiv'>
                            <label className='dateInit-r comboStyle'>Status</label>
                            <Input type="select" name="select" className="selectStyle select-arrows inputMobile marginLeft10">
                                <option>Pendiente</option>
                                <option>En curso</option>
                                <option>Ejecutar</option>
                            </Input>
                            </div>
                            <div className='input-date'>
                                <button className="btn btn-outline-secondary mobilemarginT22">Buscar</button>
                            </div>
                        </Col>
                    </Row>
                </div>
        }  
    </>
    )
}




