import React from 'react';
import { ModalBody } from 'reactstrap';
import icon_error from '../../../../../src/assets/img/icons/icon_error.jpg';
import icon_success from '../../../../../src/assets/img/icons/icon_success.jpg';

export const ModalFinalizarSubEtapa = ({ handleCloseModal, data }) => {

  return (
    <div className="popupcontent modal fade show">
      <div className="modal-dialog">
        <div className="modal-content">
          <div class="modal-header">
            <span className="titleHeader"></span>
            <div>
              <button
                type="button"
                class="close"
                aria-label="Close"
                onClick={handleCloseModal}
              >
                <span aria-hidden="true" onClick={handleCloseModal}>
                  ×
                </span>
              </button>
            </div>
          </div>
          <ModalBody>
            <div
              row
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {data.mensaje}
              </h4>
              <img src={data.estatus === 'error' ? icon_error : icon_success} className="checkFinalizar" alt="checkFinalizar" />
            </div>
          </ModalBody>
        </div>
      </div>
    </div>
  );
};
