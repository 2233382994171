import React, { useState, useEffect } from 'react';
import { Buttons } from '../../../../../Atoms/buttons';
import fernet from 'fernet';
import '../../../../../../styles/_overrides.scss';
import MultiSelect from '../../../../../Atoms/multiSelect';
import { MarkdownRechazar } from '../../../../../Atoms/markdownRechazar';
import { MarkdownEtapaAnterior } from '../../../../../Atoms/markdownEtapaAnterior';
import { dateFormat, getCookiesValue } from '../../../../../Helpers';
import { ModalComentarios } from '../../../../../Atoms/modals/modalComentarios';
import { ModalNumeroSolicitud } from '../../../../../Atoms/modals/modalNumeroSolicitud';
import { ModalCodigodeOrigen } from '../../../../../Atoms/modals/modalCodigodeOrigen';
import { ModalFinalizarSubEtapa } from '../../../../../Atoms/modals/modalFinalizarSubEtapa';
import ModalFormularioAcciones from '../../../../../Atoms/modals/modalFormularioAcciones';
import Loading from '../../../../../Atoms/loading/Loading';
import { FechaValidacionDatePicker } from '../../../../../Atoms/fechaValidacionDatePicker';
import { FechaDispercionDatePicker } from '../../../../../Atoms/fechaDispercionDatePicker';
import {
  getStyleEtapa,
  getStyleSubEtapa,
  getStyleEstatus,
} from '../../../../../../utils/randomStyle';
import axiosInstance from '../../../../../../utils/axiosInstance';
import sortalphadownsolid from '../../../../../../assets/img/icons/sort-alpha-down-solid.svg';
import sortalphaupsolid from '../../../../../../assets/img/icons/sort-alpha-up-solid.svg';
import { tokenSecret } from '../../../../../../constants';
import Inputasesor from '../../../../../Atoms/inputasesor';
import Inputrelation from '../../../../../Atoms/inputrelation';
import Inputteam from '../../../../../Atoms/inputteam';

export const TableSticky = ({
  tableClienteData,
  setTableClienteData,

  etapaPrincipal,
  isLoading,
  setIsLoading,
  catalogoPerdidaInteres,
  catalogoRechazo,

  fetchData,
}) => {
  const [showSubetapaFinalizada, setshowSubetapaFinalizada] = useState('');
  const [solicitudAcciones, setSolicitudAcciones] = useState(null);
  const [solicitudComentarios, setSolicitudComentarios] = useState(null);
  const [solicitudNumeroSolicitud, setSolicitudNumeroSolicitud] =
    useState(null);
  const [solicitudCodigoSolicitud, setSolicitudCodigoSolicitud] =
    useState(null);
  const [isFinalizando, setIsFinalizando] = useState(false);
  const [orderImg1, setOrderImg1] = useState(sortalphadownsolid);
  const [orderImg2, setOrderImg2] = useState(sortalphadownsolid);
  const [orderImg3, setOrderImg3] = useState(sortalphadownsolid);
  const [orderImg4, setOrderImg4] = useState(sortalphadownsolid);
  const [orderImg5, setOrderImg5] = useState(sortalphadownsolid);
  const [orderImg6, setOrderImg6] = useState(sortalphadownsolid);
  const [orderImg7, setOrderImg7] = useState(sortalphadownsolid);
  const [orderImg8, setOrderImg8] = useState(sortalphadownsolid);
  const [listShort, setListShort] = useState(false);
  const [rol, setRol] = useState('');

  useEffect(() => {
    let secret = new fernet.Secret(`${tokenSecret}`);
    let token = new fernet.Token({
      secret: secret,
      token:
        'gAAAAABSO_yhAAECAwQFBgcICQoLDA0OD1PGoFV6wgWZG6AOBfQqevwJT2qKtCZ0EjKy1_TvyxTseR_3ebIF6Ph-xa2QT_tEvg==',
      ttl: 0,
    });
    let cookiesAcces = getCookiesValue('accessuser');
    let resultado = token.decode(cookiesAcces);

    setRol(resultado);
  }, []);

  const orderEtapa = async () => {
    if (listShort) {
      setIsLoading(true);

      const newTableClienteDataDataDescendente = await tableClienteData.sort(
        (a, b) => {
          if (a.etapa > b.etapa) {
            return -1;
          }
          if (a.etapa < b.etapa) {
            return 1;
          }
          return 0;
        },
      );

      setTableClienteData(newTableClienteDataDataDescendente);
      setIsLoading(false);
      setOrderImg1(sortalphadownsolid);
      setListShort(false);
      return;
    }
    setIsLoading(true);

    const newTableClienteDataData = await tableClienteData.sort((a, b) => {
      if (a.etapa > b.etapa) {
        return 1;
      }
      if (a.etapa < b.etapa) {
        return -1;
      }
      return 0;
    });

    setTableClienteData(newTableClienteDataData);
    setIsLoading(false);
    setOrderImg1(sortalphaupsolid);
    setListShort(true);
  };
  const orderSubEtapa = async () => {
    if (listShort) {
      setIsLoading(true);


      const newTableClienteDataDataDescendente = await tableClienteData.sort(
        (a, b) => {
          if (a.subetapa > b.subetapa) {
            return -1;
          }
          if (a.subetapa < b.subetapa) {
            return 1;
          }
          return 0;
        },
      );

      setTableClienteData(newTableClienteDataDataDescendente);
      setIsLoading(false);
      setOrderImg2(sortalphadownsolid);
      setListShort(false);
      return;
    }
    setIsLoading(true);


    const newTableClienteDataData = await tableClienteData.sort((a, b) => {
      if (a.subetapa > b.subetapa) {
        return 1;
      }
      if (a.subetapa < b.subetapa) {
        return -1;
      }
      return 0;
    });

    setTableClienteData(newTableClienteDataData);
    setIsLoading(false);
    setOrderImg2(sortalphaupsolid);
    setListShort(true);
  };
  const orderEstatus = async () => {
    if (listShort) {
      setIsLoading(true);

      const newTableClienteDataDataDescendente = await tableClienteData.sort(
        (a, b) => {
          if (a.estatus > b.estatus) {
            return -1;
          }
          if (a.estatus < b.estatus) {
            return 1;
          }
          return 0;
        },
      );

      setTableClienteData(newTableClienteDataDataDescendente);
      setIsLoading(false);
      setOrderImg3(sortalphadownsolid);
      setListShort(false);
      return;
    }
    setIsLoading(true);

    const newTableClienteDataData = await tableClienteData.sort((a, b) => {
      if (a.estatus > b.estatus) {
        return 1;
      }
      if (a.estatus < b.estatus) {
        return -1;
      }
      return 0;
    });
    setTableClienteData(newTableClienteDataData);
    setIsLoading(false);
    setOrderImg3(sortalphaupsolid);
    setListShort(true);
  };
  const orderNumeroSolicitud = async () => {
    if (listShort) {
      setIsLoading(true);

      const newTableClienteDataDataDescendente = await tableClienteData.sort(
        (a, b) => {
          if (a.numsolicitud > b.numsolicitud) {
            return -1;
          }
          if (a.numsolicitud < b.estatus) {
            return 1;
          }
          return 0;
        },
      );

      setTableClienteData(newTableClienteDataDataDescendente);
      setIsLoading(false);
      setOrderImg4(sortalphadownsolid);
      setListShort(false);
      return;
    }
    setIsLoading(true);

    const newTableClienteDataData = await tableClienteData.sort((a, b) => {
      if (a.numsolicitud > b.numsolicitud) {
        return 1;
      }
      if (a.numsolicitud < b.numsolicitud) {
        return -1;
      }
      return 0;
    });
    setTableClienteData(newTableClienteDataData);
    setIsLoading(false);
    setOrderImg4(sortalphaupsolid);
    setListShort(true);
  };

  const orderFechaAutorizacion = async () => {
    if (listShort) {
      setIsLoading(true);

      const newTableClienteDataDataDescendente = await tableClienteData.sort(
        (a, b) => {
          if (a.fechainicio > b.fechainicio) {
            return -1;
          }
          if (a.fechainicio < b.fechainicio) {
            return 1;
          }
          return 0;
        },
      );

      setTableClienteData(newTableClienteDataDataDescendente);
      setIsLoading(false);
      setOrderImg5(sortalphadownsolid);
      setListShort(false);
      return;
    }
    setIsLoading(true);

    const newTableClienteDataData = await tableClienteData.sort((a, b) => {
      if (a.fechainicio > b.fechainicio) {
        return 1;
      }
      if (a.fechainicio < b.fechainicio) {
        return -1;
      }
      return 0;
    });
    setTableClienteData(newTableClienteDataData);
    setIsLoading(false);
    setOrderImg5(sortalphaupsolid);
    setListShort(true);
  };

  const orderTiempoStatus = async () => {
    if (listShort) {
      setIsLoading(true);

      const newTableClienteDataDataDescendente = await tableClienteData.sort(
        (a, b) => {
          if (a.tiempoestatus > b.tiempoestatus) {
            return -1;
          }
          if (a.tiempoestatus < b.tiempoestatus) {
            return 1;
          }
          return 0;
        },
      );

      setTableClienteData(newTableClienteDataDataDescendente);
      setIsLoading(false);
      setOrderImg6(sortalphadownsolid);
      setListShort(false);
      return;
    }
    setIsLoading(true);

    const newTableClienteDataData = await tableClienteData.sort((a, b) => {
      if (a.tiempoestatus > b.tiempoestatus) {
        return 1;
      }
      if (a.tiempoestatus < b.tiempoestatus) {
        return -1;
      }
      return 0;
    });
    setTableClienteData(newTableClienteDataData);
    setIsLoading(false);
    setOrderImg6(sortalphaupsolid);
    setListShort(true);
  };

  const orderFechaValidacion = async () => {
    if (listShort) {
      setIsLoading(true);

      const newTableClienteDataDataDescendente = await tableClienteData.sort(
        (a, b) => {
          if (a.fechavalidacion > b.fechavalidacion) {
            return -1;
          }
          if (a.fechavalidacion < b.fechavalidacion) {
            return 1;
          }
          return 0;
        },
      );

      setTableClienteData(newTableClienteDataDataDescendente);
      setIsLoading(false);
      setOrderImg7(sortalphadownsolid);
      setListShort(false);
      return;
    }
    setIsLoading(true);

    const newTableClienteDataData = await tableClienteData.sort((a, b) => {
      if (a.fechavalidacion > b.fechavalidacion) {
        return 1;
      }
      if (a.fechavalidacion < b.fechavalidacion) {
        return -1;
      }
      return 0;
    });
    setTableClienteData(newTableClienteDataData);
    setIsLoading(false);
    setOrderImg7(sortalphaupsolid);
    setListShort(true);
  };

  const orderFechaDispercion = async () => {
    if (listShort) {
      setIsLoading(true);

      const newTableClienteDataDataDescendente = await tableClienteData.sort(
        (a, b) => {
          if (a.fechadispercion > b.fechadispercion) {
            return -1;
          }
          if (a.fechadispercion < b.fechadispercion) {
            return 1;
          }
          return 0;
        },
      );

      setTableClienteData(newTableClienteDataDataDescendente);
      setIsLoading(false);
      setOrderImg8(sortalphadownsolid);
      setListShort(false);
      return;
    }
    setIsLoading(true);

    const newTableClienteDataData = await tableClienteData.sort((a, b) => {
      if (a.fechadispercion > b.fechadispercion) {
        return 1;
      }
      if (a.fechadispercion < b.fechadispercion) {
        return -1;
      }
      return 0;
    });

    setTableClienteData(newTableClienteDataData);
    setIsLoading(false);
    setOrderImg8(sortalphaupsolid);
    setListShort(true);
  };

  const HeadersTabla = ({ titulo }) => (
    <tr className="stickyview__tr stickyhead">
      <th className="stickyview__td--sticky stickyview__td--title">
        <div className="stickyview__div--title">{titulo}</div>
      </th>
      <th className="stickyview__td--sticky_col stickyview__td--title">
        <div className="stickyview__div--title">
          Número solicitud{' '}
          <img
            src={orderImg4}
            onClick={orderNumeroSolicitud}
            className="alpha_order"
          />
        </div>
      </th>
      <th>
        <div className="stickyview__div--content">
          Fecha de Autorización{' '}
          <img
            src={orderImg5}
            onClick={orderFechaAutorizacion}
            className="alpha_order"
          />
        </div>
      </th>
      <th>
        <div className="stickyview__div--content">
          Etapa{' '}
          <img src={orderImg1} onClick={orderEtapa} className="alpha_order" />
        </div>
      </th>
      <th>
        <div className="stickyview__div--content">
          Sub etapa
          <img
            src={orderImg2}
            onClick={orderSubEtapa}
            className="alpha_order"
          />
        </div>
      </th>
      <th>
        <div className="stickyview__div--content">
          Estatus
          <img src={orderImg3} onClick={orderEstatus} className="alpha_order" />
        </div>
      </th>
      <th>
        <div className="stickyview__div--content">Acciones</div>
      </th>
      <th>
        <div className="stickyview__div--content div_content_medium">
          Tiempo Total
        </div>
      </th>
      <th>
        <div className="stickyview__div--content div_content_medium">
          Tiempo en subetapa
          <img
            src={orderImg6}
            onClick={orderTiempoStatus}
            className="alpha_order"
          />
        </div>
      </th>
      <th>
        <div className="stickyview__div--content">Comentarios</div>
      </th>
      <th>
        <div className="stickyview__div--content">
          Fecha Validación{' '}
          <img
            src={orderImg7}
            onClick={orderFechaValidacion}
            className="alpha_order"
          />
        </div>
      </th>
      <th>
        <div className="stickyview__div--content">
          Fecha Dipersión{' '}
          <img
            src={orderImg8}
            onClick={orderFechaDispercion}
            className="alpha_order"
          />
        </div>
      </th>
      <th>
        <div className="stickyview__div--content div_content-max">
          Etapa Anterior
        </div>
      </th>
      <th>
        <div className="stickyview__div--content div_content-max">Rechazar</div>
      </th>
      <th>
        <div className="stickyview__div--content">
          El cliente no tiene interés
        </div>
      </th>
      <th>
        <div className="stickyview__div--content">Finalizar</div>
      </th>
      <th>
        <div className="stickyview__div--content">Asesor</div>
      </th>
      <th>
        <div className="stickyview__div--content">Team Leader</div>
      </th>
      <th>
        <div className="stickyview__div--content">Relationship M.</div>
      </th>
      <th>
        <div className="stickyview__div--content">Onboardings</div>
      </th>
      <th>
        <div className="stickyview__div--content">Macrocanal</div>
      </th>
      <th>
        <div className="stickyview__div--content">Canal</div>
      </th>
      <th>
        <div className="stickyview__div--content">Código de Origen</div>
      </th>
      <th>
        <div className="stickyview__div--content div_content_medium">
          Responsable Actual
        </div>
      </th>
    </tr>
  );

  const MensajesTabla = ({ numero_resultados }) => {
    if (isLoading) {
      return (
        <tr>
          <th className="stickyview__td--sticky" colSpan={2}>
            <Loading />
          </th>
        </tr>
      );
    }

    if (numero_resultados == 0) {
      return (
        <tr>
          <td className="stickyview__td--sticky" colSpan={2}>
            No se han encontrado resultados
          </td>
        </tr>
      );
    }

    return null;
  };

  const DivisionTabla = () => (
    <tr>
      <td colSpan={24} style={{ height: 35 }}></td>
    </tr>
  );
  const actualizarAutorizacionData = (index, atributo, nuevo_valor) => {
    let copy = [...tableAutorizacionData];
    if (copy[index]) {
      copy[index]['atributo_actualizado'] = atributo;
      copy[index][atributo] = nuevo_valor;
    }
    setTableAutorizacionData(copy);
  };


  const handleFinalizarSubEtapa = async solicitud => {
    try {
      setIsFinalizando(true);

      let body;

      if (
        solicitud.razonregreso &&
        solicitud.razonregreso.length > 0 &&
        solicitud.estatus === 'Devuelta'
      ) {
        body = {
          idWorkflow: 1,
          idSubEtapa: +solicitud.idsubetapa,
          idSolicitud: +solicitud.numsolicitud,
          lugarOrigen: 'Back office uniclick',
        };
      } else if (
        solicitud.razonesperdidainteres &&
        solicitud.razonesperdidainteres.length
      ) {
        body = {
          idWorkflow: 1,
          idSubEtapa: +solicitud.idsubetapa,
          idSolicitud: +solicitud.numsolicitud,
          lugarOrigen: 'Back office uniclick',
          estatusPerdida: solicitud.razonesperdidainteres.map(
            item => item.value,
          ),
        };
      } else if (solicitud.razonregreso) {
        body = {
          idWorkflow: 1,
          idSubEtapa: +solicitud.idsubetapa,
          idSolicitud: +solicitud.numsolicitud,
          lugarOrigen: 'Back office uniclick',
          estatusRegreso: solicitud.razonregreso,
        };
      } else if (solicitud.rechazo) {
        body = {
          idWorkflow: 1,
          idSubEtapa: +solicitud.idsubetapa,
          idSolicitud: +solicitud.numsolicitud,
          lugarOrigen: 'Back office uniclick',
          estatusRechazo: solicitud.rechazo,
        };
      } else {
        body = {
          idWorkflow: 1,
          idSubEtapa: +solicitud.idsubetapa,
          idSolicitud: +solicitud.numsolicitud,
          lugarOrigen: 'Back office uniclick',
        };
      }

      if (body) {
        await axiosInstance.post(`/workflows/disparadores`, body);
        setshowSubetapaFinalizada({
          estatus: 'success',
          mensaje: 'Sub etapa finalizada con éxito',
        });
        fetchData();
      }
    } catch (error) {
      setshowSubetapaFinalizada({
        estatus: 'error',
        mensaje:
          error.response && error.response.data && error.response.data.detail
            ? error.response.data.detail
            : 'Error al finalizar la sub etapa',
      });
    } finally {
      setIsFinalizando(false);
    }
  };

  return (
    <>
      <div className="stickyview__contenedor">
        <table className="stickyview__table">
          <tbody>
            {(etapaPrincipal === '' || etapaPrincipal === 'Cliente') && (
              <>
                <DivisionTabla />
                <HeadersTabla titulo={'Cliente'} />
                <MensajesTabla numero_resultados={tableClienteData.length} />
                {!isLoading &&
                  tableClienteData.map((table, index) => (
                    <tr className="stickyview__tr" key={index}>
                      <td className="stickyview__td--sticky">
                        {table.nombrecliente}
                      </td>
                      <td className="stickyview__td--sticky_col">
                        <Buttons
                          text={table.numsolicitud}
                          className="buttonHref"
                          onClick={() => setSolicitudNumeroSolicitud(table)}
                        />
                      </td>
                      <td>{dateFormat(table.fechainicio)}</td>
                      <td style={getStyleEtapa(table.etapa)}>{table.etapa}</td>
                      <td style={getStyleSubEtapa(table.subetapa)}>
                        {table.subetapa}
                      </td>
                      <td style={getStyleEstatus(table.estatus)}>
                        {table.estatus}
                      </td>
                      <td>
                        <Buttons
                          text="Ir a la acción"
                          className="buttonBC"
                          disabled={!table.esEditable}
                          onClick={() => setSolicitudAcciones(table)}
                        />
                      </td>
                      <td>{table.tiempototal}</td>
                      <td>{table.tiempoestatus}</td>
                      <td>
                        <Buttons
                          text="Comentarios"
                          className="buttonHref"
                          onClick={() => setSolicitudComentarios(table)}
                        />
                      </td>
                      <td>
                        {table.subetapa === 'Inicial' ||
                        table.subetapa === 'Autorizando' ||
                        table.subetapa === 'Documentos Actas' ||
                        table.subetapa === 'Dictamen' ||
                        table.subetapa === 'Formatos' ||
                        table.subetapa === 'Validación Expediente' ? (
                          <FechaValidacionDatePicker
                            solicitud={table}
                            value={table.fechavalidacion}
                          />
                        ) : (
                          dateFormat(table.fechavalidacion)
                        )}
                      </td>
                      <td>
                        {table.subetapa === 'Inicial' ||
                        table.subetapa === 'Autorizando' ||
                        table.subetapa === 'Documentos Actas' ||
                        table.subetapa === 'Dictamen' ||
                        table.subetapa === 'Formatos' ||
                        table.subetapa === 'Validación Expediente' ||
                        table.subetapa === 'Validación Referencias' ||
                        table.subetapa === 'Visita y Cotejo' ||
                        table.subetapa === 'Validación Visita y Cotejo' ||
                        table.subetapa === 'Validación de Cuenta' ||
                        table.subetapa === 'Generación de contrato' ||
                        table.subetapa === 'Validación de Contrato' ||
                        table.subetapa === 'Firma' ||
                        table.subetapa === 'Validación de contrato firmado' ||
                        table.subetapa === 'Dispersión' ? (
                          <FechaDispercionDatePicker
                            solicitud={table}
                            value={table.fechadispercion}
                          />
                        ) : (
                          dateFormat(table.fechadispercion)
                        )}
                      </td>
                      <td>
                        <MarkdownEtapaAnterior
                          value={table.razonregreso}
                          onChange={value =>
                            actualizarAutorizacionData(
                              index,
                              'razonregreso',
                              value,
                            )
                          }
                          options={catalogoRechazo}
                          idsubetapa={table.idsubetapa}
                          disabled={
                            !table.esEditable ||
                            !!table.rechazo ||
                            (table.razonesperdidainteres &&
                            table.razonesperdidainteres.length
                              ? true
                              : false)
                          }
                        />
                      </td>
                      <td>
                        <MarkdownRechazar
                          value={table.rechazo}
                          onChange={value =>
                            actualizarAutorizacionData(index, 'rechazo', value)
                          }
                          options={catalogoRechazo}
                          disabled={
                            !table.esEditable ||
                            !!table.razonregreso ||
                            (table.razonesperdidainteres &&
                            table.razonesperdidainteres.length
                              ? true
                              : false)
                          }
                        />
                      </td>
                      <td>
                        <MultiSelect
                          value={table.razonesperdidainteres}
                          onChange={value =>
                            actualizarAutorizacionData(
                              index,
                              'razonesperdidainteres',
                              value,
                            )
                          }
                          options={catalogoPerdidaInteres}
                          solicitud={table}
                          disabled={
                            !table.esEditable ||
                            !!table.razonregreso ||
                            !!table.rechazo
                          }
                        />
                      </td>
                      <td>
                        <Buttons
                          text="Finalizar Sub etapa"
                          className="buttonBCT"
                          disabled={!table.esEditable || isFinalizando}
                          onClick={() => handleFinalizarSubEtapa(table)}
                        />
                      </td>
                      <td>
                        {rol === 'admin' ? (
                          <Inputasesor solicitud={table} value={table.asesor} />
                        ) : (
                          table.asesor
                        )}
                      </td>
                      <td>
                        {rol === 'admin' ? (
                          <Inputteam
                            solicitud={table}
                            value={table.teamleader}
                          />
                        ) : (
                          table.teamleader
                        )}
                      </td>
                      <td>
                        {rol === 'admin' ? (
                          <Inputrelation
                            solicitud={table}
                            value={table.relationshipmanager}
                          />
                        ) : (
                          table.relationshipmanager
                        )}
                      </td>
                      <td>{table.onboardings}</td>
                      <td>{table.macrocanal}</td>
                      <td>{table.canal} </td>
                      <td>
                        <Buttons
                          text={table.codigoorigen}
                          className="buttonHref"
                          onClick={() => setSolicitudCodigoSolicitud(table)}
                        />
                      </td>
                      <td>{table.responsableactual}</td>
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>

        {!!solicitudComentarios && (
          <ModalComentarios
            solicitud={solicitudComentarios}
            handleModalClose={() => setSolicitudComentarios(null)}
          />
        )}
        {!!solicitudNumeroSolicitud && (
          <ModalNumeroSolicitud
            solicitud={solicitudNumeroSolicitud}
            handleModalClose={() => setSolicitudNumeroSolicitud(null)}
          />
        )}
        {!!solicitudCodigoSolicitud && (
          <ModalCodigodeOrigen
            handleModalClose={() => setSolicitudCodigoSolicitud(null)}
            solicitud={solicitudCodigoSolicitud}
          />
        )}
        {!!showSubetapaFinalizada && (
          <ModalFinalizarSubEtapa
            data={showSubetapaFinalizada}
            handleCloseModal={() => {
              setshowSubetapaFinalizada(null);
              fetchData();
            }}
          />
        )}
        {!!solicitudAcciones && (
          <ModalFormularioAcciones
            handleClose={() => setSolicitudAcciones(null)}
            solicitud={solicitudAcciones}
          />
        )}
      </div>
    </>
  );
};
