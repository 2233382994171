import React, { useEffect, useState } from 'react';
import {
  MdClearAll,
  MdNotificationsActive,
  MdNotificationsNone,
} from 'react-icons/md';
import withBadge from '../../hocs/withBadge';
import {
  Button,
  Nav,
  Navbar,
  NavLink,
  Popover,
  PopoverBody,
  NavItem,
} from 'reactstrap';
import bn from '../../utils/bemnames';

import Notifications from '../Moleculas/generic/Notifications';
import axiosInstance from '../../utils/axiosInstance';

const bem = bn.create('header');

const Header = () => {
  const [notificationsList, setNotificationsList] = useState([]);
  const [notificationsNumber, setNotificationsNumber] = useState(0);
  const [openNotifications, setOpenNotifications] = useState(false);

  const fetchNotificaciones = async () => {
    try {
      let result = await axiosInstance.get(`/notificaciones/pendientes`);
      let notificaciones = result.data.notificaciones || [];

      // notificaciones = notificaciones.filter(notification => notification.estatus === 'pendiente');

      notificaciones.forEach(notificacion => {
        notificacion.orden = notificacion.estatus === 'pendiente' ? 1 : 0;
      });

      notificaciones.sort(function (a, b) {
        return b.orden - a.orden;
      });

      setNotificationsList(notificaciones);
    } catch (error) {
      console.log('Error al consultar las notificaciones');
      // toast.error('Error al consultar las notificaciones');
    }
  };

  const toggleNotificationPopover = async () => {
    try {
      setOpenNotifications(!openNotifications);

      // notificaciones sin leer
      let notificaciones_sin_leer = notificationsList.filter(
        item => item.estatus === 'pendiente',
      );
      if (notificaciones_sin_leer.length) {
        // actualizar las notificaciones
        await axiosInstance.put(`notificaciones/actualizacion`, {
          id: notificaciones_sin_leer.map(item => item.id),
        });

        // pasamos las notificaciones a leidas
        let copy = notificationsList.map(item => {
          return {
            ...item,
            estatus: 'leida',
          };
        });

        // esperamos 5 seg para hacer el cambio
        setTimeout(() => {
          setNotificationsList(copy);
        }, 5000);
      }
    } catch (error) {
      console.log('Error al actualizar las notificaciones');
    }
  };

  const handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  const MdNotificationsActiveWithBadge = withBadge({
    size: 'md',
    color: 'primary',
    style: {
      top: -10,
      right: -10,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    children: <small>{notificationsNumber}</small>,
  })(MdNotificationsActive);

  useEffect(() => {
    setNotificationsNumber(
      notificationsList.filter(item => item.estatus === 'pendiente').length,
    );
  }, [notificationsList]);

  useEffect(() => {
    fetchNotificaciones();
  }, []);

  return (
    <Navbar light expand className={bem.b('bg-white')}>
      <Nav navbar className="mr-2">
        <Button outline onClick={handleSidebarControlButton}>
          <MdClearAll size={25} />
        </Button>
      </Nav>

      <Nav navbar className={bem.e('nav-right')}>
        <NavItem className="d-inline-flex">
          <NavLink id="Popover1" className="position-relative">
            {notificationsNumber === 0 ? (
              <MdNotificationsNone
                size={25}
                className="text-secondary can-click"
                onClick={toggleNotificationPopover}
              />
            ) : (
              <MdNotificationsActiveWithBadge
                size={25}
                className="text-secondary can-click animated swing infinite"
                onClick={toggleNotificationPopover}
              />
            )}
          </NavLink>
          <Popover
            placement="bottom"
            isOpen={openNotifications}
            toggle={toggleNotificationPopover}
            target="Popover1"
            trigger="focus"
          >
            <PopoverBody>
              <Notifications notificationsList={notificationsList} />
            </PopoverBody>
          </Popover>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Header;
