import React from 'react';
import { Title } from '../../../components/Atoms/title';
import { Tables } from '../../../components/Moleculas/pantallas/reportes/reporteNumSolicitud/tables';

export const ReporteNumSolicitud = ({ match }) => {
  const { params } = match;

  return (
    <>
      <Title text="Reporte de Número Solicitud" />
      <Tables idSolicitud={params.id} />
    </>
  );
};
