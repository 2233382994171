import React from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';


export const Tables = ({titleTable1,titleTable2,titleTable3,titleTable4,titleTable5,titleTable6,content1,content2,content3,content4,content5,content6,content7,content8,content9,content10,content11,content12,content13,content14,content15,content16,content17,content18,content19,content20,content21,content22,content23,content24}) => {

  return (
    <>
      <Row className="p-3">
        <Col>
          <Card className="mb-3">
            <CardBody className="text-center">
              <Table responsive>
                <thead>
                  <tr>
                    <th>{titleTable1}</th>
                    <th>{titleTable2}</th>
                    <th>{titleTable3}</th>
                    <th>{titleTable4}</th>
                    <th> {titleTable5}</th>
                    <th colspan="2" align='center'> {titleTable6}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>{content1}</td>
                    <td>{content2}</td>
                    <td>{content3}</td>
                    <td>{content4}</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>{content5}</td>
                    <td>{content6}</td>
                    <td>{content7}</td>
                    <td>{content8}</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>{content9}</td>
                    <td>{content10}</td>
                    <td>{content11}</td>
                    <td>{content12}</td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>{content13}</td>
                    <td>{content14}</td>
                    <td>{content15}</td>
                    <td>{content16}</td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>{content17}</td>
                    <td>{content18}</td>
                    <td>{content19}</td>
                    <td>{content20}</td>
                  </tr>
                  <tr>
                    <th scope="row">6</th>
                    <td>{content21}</td>
                    <td>{content22}</td>
                    <td>{content23}</td>
                    <td>{content24}</td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

