import React, { useEffect, useState } from 'react';
import '../../../styles/_overrides.scss';
import Select from 'react-select';

const colourStyles = {
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.style ? data.style.backgroundColor : '',
      color: data.style ? data.style.color : '',
    };
  },
  multiValueLabel: (styles, { data }) => {
    return {
      ...styles,
      color: data.style ? data.style.color : '',
    }
  },
};

const MultiSelect = ({ value, options, onChange, disabled }) => {

  const [seleccion, setSeleccion] = useState([]);

  const handleGuardarSeleccion = async (values) => {
    setSeleccion(values);
    onChange(values);
  };

  useEffect(() => {
    if (Array.isArray(value)) {
      let opciones_seleccionadas = [];
      value.forEach(val => {
        let opcion_seleccionada = options.find(option => option.value === val);
        if (opcion_seleccionada) {
          opciones_seleccionadas.push(opcion_seleccionada);
        }
      });
      setSeleccion(opciones_seleccionadas);
    }
  }, [options]);

  return (
    <Select
      placeholder={''}
      isMulti
      closeMenuOnSelect={false}
      styles={colourStyles}
      isDisabled={disabled}
      value={seleccion}
      options={options || []}
      onChange={handleGuardarSeleccion}
    />
  );
};

export default MultiSelect;
