import React, { useEffect, useState } from 'react';
import { Title } from '../../../components/Atoms/title';
import { TableSticky } from '../../../components/Moleculas/pantallas/reportes/reporteSolicitud/tableSticky';
import { Col, Input, Row, Button, FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import es from 'date-fns/locale/es';
import toast from 'react-hot-toast';
import 'react-datepicker/dist/react-datepicker.css';
import axiosInstance from '../../../utils/axiosInstance';
import ReactExport from 'react-data-export';
import { dateFormat } from '../../../components/Helpers';
import imageexcel from '../../../assets/img/icons/imageexcel.PNG';

export const ReporteSolicitud = () => {
  const [tableAutorizacionData, setTableAutorizacionData] = useState([]);
  const [tableEtapaData, setTableEtapaDataList] = useState([]);
  const [tablesubEtapaData, setTableSubEtapaDataList] = useState([]);
  const [tablestatusData, setTableStatusDataList] = useState([]);
  const [etapa, setEtapa] = useState('');
  const [subEtapa, setSubetapa] = useState('');
  const [estatus, setEstatus] = useState('');
  const fechaInicioAnio = moment().startOf('year');
  const fechaActual = moment();
  const fechaMin = moment().subtract(2, 'years').toDate();
  const fechaMax = moment().toDate();
  const [fechaInicio, setFechaInicio] = useState(fechaInicioAnio.toDate());
  const [fechaFin, setFechaFin] = useState(fechaActual.toDate());
  const [isLoading, setIsLoading] = useState(false);
  const [showBotonLimpiar, setShowBotonLimpiar] = useState(false);
  const [filters, setFilters] = useState([]);
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const [datos, setDatos] = useState([]);
  const [catalogoDato, setcatalogoDato] = useState([]);

  const fetchCatalogos = async () => {
    try {
      let catalogo_etapas = await axiosInstance.get(
        `/catalogos?nombre_catalogo=catalogoetapas`,
      );
      let catalogo_subetapas = await axiosInstance.get(
        `/catalogos?nombre_catalogo=catalogosubetapas`,
      );
      let catalogo_estatus = await axiosInstance.get(
        `/catalogos?nombre_catalogo=catalogoestatus`,
      );

      setTableEtapaDataList(catalogo_etapas.data.elementos);
      setTableSubEtapaDataList(catalogo_subetapas.data.elementos);
      setTableStatusDataList(catalogo_estatus.data.elementos);
      setcatalogoDato(catalogo_etapas.data.elementos);
    } catch (err) {
      console.log('Error al consultar los catalogos', err);
      if (err && err.response && err.response.status == 503) {
        console.log('status', err.response.status);
      } else {
        toast.error('Error al consultar los catalogos');
        console.log('Error al consultar los catalogos', err);
      }
    }
  };

  const fetchData = async (
    _fechaInicio,
    _fechaFin,
    _subEtapa,
    _estatus,
    _etapa,
  ) => {
    try {
      setIsLoading(true);

      let _fecha_inicio = _fechaInicio
        ? moment(_fechaInicio).format('YYYY-MM-DD')
        : fechaInicioAnio.format('YYYY-MM-DD');
      let _fecha_fin = _fechaFin
        ? moment(_fechaFin).format('YYYY-MM-DD')
        : fechaActual.format('YYYY-MM-DD');

      // validar si hay filtros seleccionados
      let tiene_filtros =
        _subEtapa ||
        _estatus ||
        _etapa ||
        _fecha_inicio !== fechaInicioAnio.format('YYYY-MM-DD') ||
        _fecha_fin != fechaActual.format('YYYY-MM-DD')
          ? true
          : false;

      // armar las urls
      let url_responseAutorizacion = `/solicitudes/consulta?fecha_inicio=${_fecha_inicio}&fecha_fin=${_fecha_fin}&id_workflow=1`;

      if (_subEtapa) {
        url_responseAutorizacion += `&subetapa=${_subEtapa}`;
      }

      if (_etapa) {
        _etapa.forEach(etapa => {
          url_responseAutorizacion += `&etapa=${etapa}`;
        });
      } else {
        const checkbox = document.querySelectorAll('.form-check-input');
        const check = Object.values(checkbox);
        check.forEach(e => (e.checked = false));

        url_responseAutorizacion += `&etapa=Autorizacion&etapa=Contratacion&etapa=Expediente&etapa=Cliente`;
      }

      if (_estatus) {
        url_responseAutorizacion += `&estatus=${_estatus}`;
      } else {
        url_responseAutorizacion += `&estatus=Pendiente&estatus=En Proceso&estatus=Devuelta`;
      }

      // consultar las solicitudes
      let dataSolicitudesAutorizacion = await axiosInstance.get(
        url_responseAutorizacion,
      );

      // mapear las solicitudes
      let solicitudes_autorizacion =
        dataSolicitudesAutorizacion.data.solicitudes;

      // ordenar por numero de solicitud
      solicitudes_autorizacion.sort((a, b) => a.numsolicitud - b.numsolicitud);

      // asignar los datos al state
      setShowBotonLimpiar(tiene_filtros);
      setTableAutorizacionData(solicitudes_autorizacion);
      setDatos(Object.keys(solicitudes_autorizacion[0]));
    } catch (err) {
      console.log('Error al consultar las solicitudes', err);
      if (err && err.response && err.response.status == 503) {
        console.log('status', err.response.status);
      } else {
        // toast.error('Error al consultar las solicitudes');
        console.log('Error al consultar las solicitudes', err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const removeAccents = str => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
  const handleFilters = event => {
    const filtro = event.target.value;
    const cadena = removeAccents(filtro);
    if (!event.target.checked) {
      const newFilter = filters.filter(e => e !== cadena);
      setFilters(newFilter);
      console.log(newFilter, 'newFilter');
    }
    if (filters.includes(cadena)) {
      return;
    }
    if (event.target.checked) {
      setFilters([...filters, cadena]);
      return;
    }
  };

  const handleLimpiar = () => {
    setEtapa('');
    setSubetapa('');
    setEstatus('');
    setFechaInicio(moment().startOf('year').toDate());
    setFechaFin(moment().toDate());
    fetchData(null, null, null, null);
  };

  const handleBuscar = () => {
    fetchData(fechaInicio, fechaFin, subEtapa, estatus, filters);
  };

  useEffect(() => {
    fetchCatalogos();
    fetchData();
  }, []);

  return (
    <>
      <Title text="Reporte de Solicitudes" />
      <div className="styleMob paddingbottom">
        <Row>
          {catalogoDato.length > 0 && (
            <>
              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <FormGroup>
                  <Label>Rango de fecha:</Label>
                  <DatePicker
                    locale={es}
                    selectsRange={true}
                    startDate={fechaInicio}
                    endDate={fechaFin}
                    minDate={fechaMin}
                    maxDate={fechaMax}
                    onChange={update => {
                      setFechaInicio(update[0]);
                      setFechaFin(update[1]);
                    }}
                    className="react-datepicker__input dateStyle inputMobile"
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <FormGroup className="">
                  <label className="dateInit-r margin-ln19">Etapa:</label>
                  <br />
                  {tableEtapaData.map((etapa, index) => {
                    return (
                      <>
                        <label className="withmax">
                          <input
                            type="checkbox"
                            id={`cbox1${index}`}
                            className="form-check-input"
                            value={etapa.descripcion}
                            onChange={handleFilters}
                          />
                          {etapa.descripcion}
                        </label>
                      </>
                    );
                  })}
                </FormGroup>
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <FormGroup>
                  <label className="dateInit-r">Subetapa:</label>
                  <Input
                    type="select"
                    name="select"
                    className="select-arrows inputMobile"
                    value={subEtapa}
                    onChange={event => setSubetapa(event.target.value)}
                  >
                    <option value="">Selecciona</option>
                    {tablesubEtapaData.map((subetapa, index) => {
                      return (
                        <option key={index}>{subetapa.descripcion}</option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <FormGroup>
                  <label className="dateInit-r comboStyle">Estatus:</label>
                  <Input
                    type="select"
                    name="select"
                    className="select-arrows inputMobile"
                    value={estatus}
                    onChange={event => setEstatus(event.target.value)}
                  >
                    <option value="">Selecciona</option>
                    {tablestatusData.map((status, index) => {
                      return <option key={index}>{status.descripcion}</option>;
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </>
          )}

          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            className="botones_exportar"
          >
            <div>
              {tableAutorizacionData.length > 0 && (
                <ExcelFile
                  filename="Reporte de Solicitudes"
                  element={
                    <button className="buttonexport mobilemarginT22">
                      <img src={imageexcel} height="32" />
                    </button>
                  }
                >
                  <ExcelSheet
                    data={tableAutorizacionData}
                    name="Reporte de Solicitudes"
                  >
                    <ExcelColumn label="Cliente" value="nombrecliente" />
                    <ExcelColumn
                      label="Número solicitud"
                      value="numsolicitud"
                    />
                    <ExcelColumn
                      label="Fecha de Autorización	"
                      value={col =>
                        col.fechainicio === null
                          ? ''
                          : dateFormat(col.fechainicio)
                      }
                    />
                    <ExcelColumn label="Etapa" value="etapa" />
                    <ExcelColumn label="Sub etapa" value="subetapa" />
                    <ExcelColumn label="Estatus" value="estatus" />
                    <ExcelColumn label="Tiempo Total" value="tiempototal" />
                    <ExcelColumn
                      label="Tiempo en estatus"
                      value="tiempoestatus"
                    />
                    <ExcelColumn
                      label="Comentarios"
                      value={col =>
                        col.comentarios === null
                          ? ''
                          : col.comentarios[0].comentario
                      }
                    />

                    <ExcelColumn
                      label="Fecha Validacion"
                      value={col =>
                        col.fechavalidacion === null
                          ? ''
                          : dateFormat(col.fechavalidacion)
                      }
                    />
                    <ExcelColumn
                      label="Fecha Dipersión"
                      value={col =>
                        col.fechadispercion === null
                          ? ''
                          : dateFormat(col.fechadispercion)
                      }
                    />

                    <ExcelColumn label="Asesor" value="asesor" />
                    <ExcelColumn label="Team Leader" value="teamleader" />
                    <ExcelColumn label="Relationship M." value="teamleader" />
                    <ExcelColumn label="Onboardings" value="onboardings" />
                    <ExcelColumn label="Macrocanal" value="macrocanal" />
                    <ExcelColumn label="Canal" value="canal" />
                    <ExcelColumn
                      label="Código de Origen	"
                      value="codigoorigen"
                    />
                    <ExcelColumn
                      label="Responsable Actual	"
                      value="responsableactual"
                    />
                    <ExcelColumn label="Etapa Anterior	" value="razonregreso" />
                    <ExcelColumn
                      label="El cliente no tiene interés	"
                      value="razonesperdidainteres"
                    />
                    <ExcelColumn label="Rechazar" value="rechazo" />
                  </ExcelSheet>
                </ExcelFile>
              )}
            </div>
          </Col>

          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            className="botones_busqueda"
          >
            {' '}
            {showBotonLimpiar && (
              <Button
                type="button"
                onClick={handleLimpiar}
                disabled={isLoading}
                style={{ marginRight: 15 }}
              >
                Borrar filtros
              </Button>
            )}
            <Button type="button" onClick={handleBuscar} disabled={isLoading}>
              Buscar
            </Button>
          </Col>
        </Row>
      </div>

      <TableSticky
        tableAutorizacionData={tableAutorizacionData}
        isLoading={isLoading}
      />
    </>
  );
};
