import React, { useState, useEffect } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import fernet from 'fernet';
import { getCookiesValue } from '../../../../../components/Helpers';
import { apiRoot, tokenSecret } from '../../../../../constants';
import axiosInstance from '../../../../../utils/axiosInstance';
import toast from 'react-hot-toast';
import './style.css';

export const Forms = () => {
  const [emailError, setEmailError] = useState('');
  const [rolUser, setRolUser] = useState(false);
  const [form, setForm] = useState('');
  const [user, setUser] = useState({
    email: '',
    password: '',
    password_confirm: '',
  });

  const handleChange = event => {
    setForm(event.target.value);
  };

  const cookiesAcces = getCookiesValue('accesstoken');
  var myHeaders = new Headers();
  myHeaders.append('access_token', `${cookiesAcces}`);
  myHeaders.append('Content-Type', 'application/json');

  const fetchCatalogo = async () => {
    try {
      let result = await axiosInstance.get(
        `/catalogos?nombre_catalogo=catalogoroles`,
      );

      const data = result.data;
      setRolUser(data.elementos);
      setForm(data.elementos[0].descripcion);
    } catch (err) {
      toast.error('Error al obtener el catalogo de roles');
    } finally {
    }
  };

  useEffect(() => {
    fetchCatalogo();
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    var email = document.getElementById('email').value;
    var password = document.getElementById('password').value;
    var password_confirm = document.getElementById('password_confirm').value;
    var select_rol = document.getElementById('select_rol').value;

    let password_exp = new RegExp(/^(?=.*?\d)(?=.*?[a-zA-Z_-])[a-zA-Z\d]+$/);

    if (
      !email ||
      !password ||
      !password_confirm ||
      !select_rol ||
      !email.length ||
      !password.length ||
      !password_confirm.length ||
      !select_rol.length
    ) {
      setEmailError('*Por favor debe llenar los datos');
    } else if (password.length < 10) {
      setEmailError('*La contraseña debe ser mayor a 10 digitos');
    } else if (!password_exp.test(password)) {
      setEmailError('*La contraseña debe contener numeros y letras');
    } else if (password !== password_confirm) {
      setEmailError('*Las contraseñas no coinciden');
      return;
    } else if (email.includes('@') === false) {
      setEmailError('Incluye un signo "@" en la dirección de correo.');
    } else if (email.includes('@uniclick.mx') === false) {
      setEmailError('Correo con dominio invalido');
    } else {
      try {
        let secret = new fernet.Secret(`${tokenSecret}`);
        var token = new fernet.Token({
          secret: secret,
          time: Date.parse(1),
          iv: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        });
        const passwordencode = token.encode(user.password);

        var raw = JSON.stringify({
          correo: user.email.toLowerCase(),
          password: passwordencode,
          rol: form,
          estatususuario: 'Activo',
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        };

        //Cambio async - error

        fetch(`${apiRoot}/accesos/registro`, requestOptions)
          .then(response => response.text())
          .then(result => {
            const resultado = JSON.parse(result);
            const resultadoSucces = resultado;
            if (
              resultadoSucces.detail === 'Alta con exito' ||
              resultadoSucces.mensaje === 'Alta con exito'
            ) {
              toast.success('Usuario agregado corretamente');

              setTimeout(() => {
                window.location.reload();
              }, 1200);
              const clase13 =
                window.document.getElementsByClassName('popupcontent');
              for (var i = 0; i < clase13.length; i++) {
                clase13[i].setAttribute('style', 'display: none;');
              }
              var email = (document.getElementById('email').value = '');
              var password = (document.getElementById('password').value = '');
              var password_confirm = (document.getElementById(
                'password_confirm',
              ).value = '');
            }

            const errorResultado = resultado;
            if (errorResultado.detail === 'El usuario ya existe') {
              var email = (document.getElementById('email').value = '');
              var password = (document.getElementById('password').value = '');
              var password_confirm = (document.getElementById(
                'password_confirm',
              ).value = '');
              setEmailError(errorResultado.detail);
            }
          })
          .catch(error => console.log('error', error));
      } catch (error) {
        alert('error', error);
      }
    }
  };

  const handleModalClose = () => {
    const clase13 = window.document.getElementsByClassName('popupcontent');
    for (var i = 0; i < clase13.length; i++) {
      clase13[i].setAttribute('style', 'display: none;');
    }
  };

  return (
    <>
      <Row>
        <Col xl={12} lg={12} md={12}>
          <form onSubmit={handleSubmit} autoComplete="nope">
            <FormGroup row>
              <Label for="exampleEmail" className="formStyle-us" sm={3}>
                Correo
              </Label>
              <Col sm={9}>
                <Input
                  placeholder="correo@uniclick.mx"
                  type="email"
                  id="email"
                  name="email"
                  onChange={e => setUser({ ...user, email: e.target.value })}
                  autoComplete="new-email"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="examplePassword" className="formStyle-us" sm={3}>
                Contraseña
              </Label>
              <Col sm={9}>
                <Input
                  type="password"
                  onChange={e => setUser({ ...user, password: e.target.value })}
                  name="password"
                  id="password"
                  placeholder="Contraseña"
                  autoComplete="new-password"
                />{' '}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="examplePassword" className="formStyle-us" sm={3}>
                Confirmar Contraseña
              </Label>
              <Col sm={9}>
                <Input
                  type="password"
                  onChange={e =>
                    setUser({ ...user, password_confirm: e.target.value })
                  }
                  name="password_confirm"
                  id="password_confirm"
                  placeholder="Contraseña"
                />{' '}
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="examplePassword" className="formStyle-us" sm={3}>
                Rol
              </Label>
              <Col sm={9}>
                <Input
                  type="select"
                  onChange={handleChange}
                  name="select_rol"
                  id="select_rol"
                  className="selectStyle select-arrows inputMobile "
                >
                  <option value="">Selecciona</option>
                  {rolUser ? (
                    rolUser.map((rol, index) => {
                      return (
                        <option key={index} value={rol.descripcion}>
                          {rol.descripcion}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Input>
              </Col>
            </FormGroup>

            {emailError.length > 0 && (
              <div className="divstyleAlertModal">
                <label class="styleAlertmodal text-red">{emailError}</label>
              </div>
            )}
            <div class="modal-footer mfooter">
              <button
                type="button"
                class="btn btn-danger"
                onClick={handleModalClose}
              >
                Cancelar
              </button>
              <button className="btn btn-secondary">Guardar</button>
            </div>
          </form>
        </Col>
      </Row>
    </>
  );
};
