import React, { useEffect, useState } from 'react';
import { HeaderLogin } from '../components/Layout/Login/headerLogin';
import { FooterLogin } from '../components/Layout/Login/footerLogin';
import axios from 'axios';
import fernet from 'fernet';
import { tokenSecret } from '../constants';
import { callcookie } from '../components/Helpers';

const AuthPage = (props) => {

  const [loginMessage, setLoginMessage] = useState('Espere por favor...');

  const loginWithTokenUrl = async () => {
    try{
      let queryParams = new URLSearchParams(window.location.search);
      let externalToken = queryParams.get('token');

      if(externalToken){
        let responseToken = await axios.post(`${process.env.REACT_APP_API_KONG}/accesos/login/interno`, {}, {
          headers: {
            'authorization': `Bearer ${externalToken}`
          }
        });
        let responseData = responseToken.data;
        let secret = new fernet.Secret(`${tokenSecret}`);
        let token = new fernet.Token({
          secret: secret,
          time: Date.parse(1),
          iv: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        });

        callcookie('accesstoken', responseData.access_token, 1);
        callcookie('accessuser', token.encode(responseData.rol), 1);
        callcookie('user', token.encode(responseData.email), 1);
        setLoginMessage('');
        props.history.push('/');
      }
    } catch(err){
      setLoginMessage('Ha ocurrido un error al iniciar sesión');
      console.error('Error al generar nuevo token', err);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      loginWithTokenUrl();
    }, 500);
  }, []);

  return (
    <>
      <HeaderLogin />
      <div style={{
        marginTop: '6rem',
        marginBottom: '2rem',
      }}>
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '2rem',
          }}
        >
          {loginMessage}
        </span>

      </div>
      <FooterLogin />
    </>
  );
}

export default AuthPage;
