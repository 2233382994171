import React, { useState, useEffect } from 'react';
import getRandomStyle from '../../../utils/randomStyle';
import DynamicIcon from '../../../utils/DynamicIcon';

export const MarkdownRechazar = ({ value, onChange, options, disabled }) => {

  const [open, setOpen] = useState(false);
  const [style, setStyle] = useState({});
  const containerRef = React.useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (value && !style.background) {
      setStyle(getRandomStyle('rechazar'));
    }
  }, []);

  const handleclickInput = event => {
    event.preventDefault();
    if (disabled) {
      return;
    }
    setOpen(!open);
  };

  const handleSelectOpcion = item => {
    if (disabled) {
      return;
    }
    onChange(item ? item.descripcion : '');
    setStyle(item ? item.style : {});
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={`markdownContainer ${disabled ? 'disabled' : ''}`}
        style={style}
      >
        <div className="markdownContainerTextButtonClear">
          <input
            type="text"
            onClick={handleclickInput}
            className="markdownContainerTextButtonClear__input"
            value={value}
            readOnly
            style={style}
          />
          <div class=" css-1hb7zxy-IndicatorsContainer mrimg">
            <span class=" css-1okebmr-indicatorSeparator"></span>
            <div class=" css-tlfecz-indicatorContainer" aria-hidden="true">
              <svg
                height="20"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                class="css-tj5bde-Svg"
              >
                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
              </svg>
            </div>
          </div>

          {value && !disabled && (
            <div
              className="markdownContainerTextButtonClear__button"
              onClick={() => handleSelectOpcion(null)}
            >
              <DynamicIcon fill={style ? style.color : ''} />
            </div>
          )}
        </div>

        <ul className={`markdownMenu  ${open ? 'markdownView' : ''}`}>
          {(options || []).map((opcion, index) => (
            <li
              key={index}
              className={`markdownList`}
              style={opcion.style}
              onClick={() => handleSelectOpcion(opcion)}
            >
              {opcion.descripcion}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
