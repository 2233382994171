import React, { useState } from 'react';
import toast from 'react-hot-toast';
import axiosInstance from '../../../utils/axiosInstance';
import save from '../../../assets/img/icons/save-regular.svg';
import edit from '../../../assets/img/icons/edit-solid.svg';

const Inputasesor = ({ solicitud, value }) => {
  const [img, setImage] = useState(edit);
  const [values, setValue] = useState('');

  const conexionData = async () => {
    try {
      let data = {
        numsolicitud: `${solicitud.numsolicitud}`,
        asesor: `${values}`,
        idworkflow: 1,
      };
      await axiosInstance.put(
        `/solicitudes/actualizar_usuario_asignacion`,
        data,
      );
      toast.success('Asesor guardado correctamente');
      var classInputSave = document.getElementsByClassName(
        `input-etapa-value-${solicitud.numsolicitud}`,
      );
      for (var i = 0; i < classInputSave.length; i++) {
        classInputSave[i].setAttribute(
          'style',
          'background: #f0f0f0;pointer-events: none;',
        );
      }
      setImage(edit);
      handlePlaceHolder();
      handleValues();
    } catch (error) {
      console.log(error);
      toast.error('Error al guardar el asesor');
    } finally {
    }
  };

  const handleChange = event => {
    setImage(save);
    setValue(event.target.value);
  };

  const handlePlaceHolder = () => {
    let input = document.getElementById(
      `input-etapa-value-${solicitud.numsolicitud}`,
    );
    input.placeholder = 'Ingresa tu correo ee';
    input.value = `fg`;
  };

  const handleValues = () => {
    let input = document.getElementById(
      `input-etapa-value-${solicitud.numsolicitud}`,
    );
    if (values) {
      input.value = `${values}`;
      setValue('');
    } else {
      input.value = '';
    }
  };

  const handleClick = () => {
    if (values === '') {
      handleValues();
      let input = document.getElementById(
        `input-etapa-value-${solicitud.numsolicitud}`,
      );
      input.placeholder = 'Ingresa tu correo';

      var classInput = document.getElementsByClassName(
        `input-etapa-value-${solicitud.numsolicitud}`,
      );
      for (var i = 0; i < classInput.length; i++) {
        classInput[i].setAttribute(
          'style',
          'background: white;pointer-events: auto;',
        );
      }
    } else if (values.length === 0) {
      let input = document.getElementById(
        `input-etapa-value-${solicitud.numsolicitud}`,
      );
      input.placeholder = 'Ingresa tu correo';

      var classInput = document.getElementsByClassName(
        `input-etapa-value-${solicitud.numsolicitud}`,
      );
      for (var i = 0; i < classInput.length; i++) {
        classInput[i].setAttribute(
          'style',
          'background: white;pointer-events: auto;',
        );
      }
    } else if (
      (values.length > 0 && values.includes('@') === false) ||
      values.includes('.') === false
    ) {
      toast.error('La dirección de email es incorrecta.');
    }

    if (values.includes('@') && values.includes('.')) {
      conexionData();
    }
  };

  return (
    <div className="display-flex input-asesor">
      <input
        className={`dateStyle react-datepicker__input_date text-center inputetapa input-etapa-value-${solicitud.numsolicitud}`}
        placeholder={value}
        onChange={handleChange}
        id={`input-etapa-value-${solicitud.numsolicitud}`}
      ></input>
      <button className="div-asesor" onClick={handleClick}>
        <img src={img} className="img-asesor" type="email" />
      </button>
    </div>
  );
};

export default Inputasesor;
