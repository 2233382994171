import fernet from 'fernet';
import { tokenSecret } from '../constants';

export default accessuser => {
  try {
    let secret = new fernet.Secret(`${tokenSecret}`);
    let token = new fernet.Token({
      secret: secret,
      token:
        'gAAAAABSO_yhAAECAwQFBgcICQoLDA0OD1PGoFV6wgWZG6AOBfQqevwJT2qKtCZ0EjKy1_TvyxTseR_3ebIF6Ph-xa2QT_tEvg==',
      ttl: 0,
    });
    return token.decode(accessuser);
  } catch (err) {
    return null;
  }
};
