import React from 'react'

const Loading = (props) => {
    return (
        <div className="lds-ring" {...props}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default Loading;