import { callcookie, getCookiesValue } from '../components/Helpers';
import fernet from 'fernet';
import { tokenSecret } from '../constants';

const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch (err) {
    return null;
  }
};

export const isLogin = () => {
  if (getCookiesValue('accessuser') && getCookiesValue('accesstoken')) {
    return true;
  }
  return false;
};

export const setLoginToken = (autoken, rol) => {
  const _tokenD = parseJwt(autoken);

  if (!_tokenD || Date.now() >= _tokenD.exp * 1000) return;

  const secret = new fernet.Secret(`${tokenSecret}`);
  const token = new fernet.Token({
    secret: secret,
    time: Date.parse(1),
    iv: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
  });

  const rolEncode = token.encode(rol);
  const emailEncode = token.encode(_tokenD.email);

  callcookie('accesstoken', autoken, 1);
  callcookie('accessuser', rolEncode, 1);
  callcookie('user', emailEncode, 1);
}
