import React from 'react'
import './style.css'

export const Title = ({text,className= 'titles'}) => {
    return (
        <h1 className={className}>
        {text} 
        </h1>
    )
}

