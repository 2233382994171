import React, { useState } from 'react';
import { Col, ModalBody, Row } from 'reactstrap';
import { Forms } from '../../forms';

import './style.css';

export const Options = ({ titleAdd }) => {
  const [show, setShow] = useState(false);

  const handleModalClose = () => {
    setShow(false);
    const classHeigth = window.document.getElementsByClassName(
      'cr-content container-fluid',
    );
    for (var f = 0; f < classHeigth.length; f++) {
      classHeigth[f].removeAttribute('style');
    }
  };

  const handleModalOpen = () => {
    setShow(true);
    const clase13 = window.document.getElementsByClassName('popupcontent');
    for (var a = 0; a < clase13.length; a++) {
      clase13[a].removeAttribute('style');
    }
    const classHeigth = window.document.getElementsByClassName(
      'cr-content container-fluid',
    );
    for (var i = 0; i < classHeigth.length; i++) {
      classHeigth[i].setAttribute('style', 'max-height: initial');
    }
  };

  return (
    <>
      <Row className="App">
        <Col md="12" sm="12" xs="12">
          <button
            onClick={handleModalOpen}
            className="btn btn-outline-secondary"
          >
            {titleAdd}
          </button>
          {show && (
            <>
              <div className="popupcontent modal fade show">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Agregar Usuario</h5>
                      <button type="button" class="close" aria-label="Close">
                        <span aria-hidden="true" onClick={handleModalClose}>
                          ×
                        </span>
                      </button>
                    </div>
                    <ModalBody>
                      <Forms />
                    </ModalBody>
                  </div>
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
