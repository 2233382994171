export const styles = [
  { backgroundColor: '#D98880', color: 'white' },
  { backgroundColor: '#F1948A', color: 'white' },
  { backgroundColor: '#BB8FCE', color: 'white' },
  { backgroundColor: '#3498DB', color: 'white' },
  { backgroundColor: '#73C6B6', color: '#343a40' },
  { backgroundColor: '#82E0AA', color: '#343a40' },
  { backgroundColor: '#F1C40F', color: 'white' },
  { backgroundColor: '#F39C12', color: 'white' },
  { backgroundColor: '#D35400', color: 'white' },
  { backgroundColor: '#CD6155', color: 'white' },
  { backgroundColor: '#EC7063', color: 'white' },
  { backgroundColor: '#AF7AC5', color: 'white' },
  { backgroundColor: '#A569BD', color: 'white' },
  { backgroundColor: '#5499C7', color: 'white' },
  { backgroundColor: '#5DADE2', color: 'white' },
  { backgroundColor: '#48C9B0', color: '#343a40' },
  { backgroundColor: '#45B39D', color: '#343a40' },
  { backgroundColor: '#52BE80', color: '#343a40' },
  { backgroundColor: '#58D68D', color: '#343a40' },
  { backgroundColor: '#F4D03F', color: '#343a40' },
  { backgroundColor: '#F5B041', color: 'white' },
  { backgroundColor: '#EB984E', color: 'white' },
  { backgroundColor: '#DC7633', color: 'white' },
  { backgroundColor: '#5D6D7E', color: 'white' },
  { backgroundColor: '#566573', color: 'white' },
  { backgroundColor: '#C0392B', color: 'white' },
  { backgroundColor: '#E74C3C', color: 'white' },
  { backgroundColor: '#8E44AD', color: 'white' },
  { backgroundColor: '#3498DB', color: 'white' },
  { backgroundColor: '#16A085', color: 'white' },
  { backgroundColor: '#27AE60', color: 'white' },
  { backgroundColor: '#2ECC71', color: 'white' },
  { backgroundColor: '#34495E', color: 'white' },
  { backgroundColor: '#2C3E50', color: 'white' },
];

export const styles_estatus = {
  Pendiente: { backgroundColor: '#56B881', color: 'white' },
  'En proceso': { backgroundColor: '#41AFA5', color: 'white' },
  Finalizada: { backgroundColor: '#34BE5B', color: 'white' },
  Rechazada: { backgroundColor: '#E55E5E', color: 'white' },
  Devuelta: { backgroundColor: '#FBB03B', color: 'white' },
  'Sin interes': { backgroundColor: '#50667F', color: 'white' },
};

export const styles_etapa = {
  Autorizacion: { backgroundColor: '#1AAFD0', color: 'white' },
  Expediente: { backgroundColor: '#6A67CE', color: 'white' },
  Contratacion: { backgroundColor: '#FFB900', color: 'white' },
  Cliente: { backgroundColor: '#56A261', color: 'white' },
};

export const styles_subetapa = {
  Inicial: { backgroundColor: '#922b21', color: 'white' },
  Autorizando: { backgroundColor: '#0085AD', color: 'white' },
  'Documentos Actas': { backgroundColor: '#A05CD2', color: 'white' },
  Dictamen: { backgroundColor: '#B94FB1', color: 'white' },
  Formatos: { backgroundColor: '#B94F7C', color: 'white' },
  'Validación Expediente': { backgroundColor: '#B9574F', color: 'white' },
  'Validación Referencias': { backgroundColor: '#B98C4F', color: 'white' },
  'Visita y Cotejo': { backgroundColor: '#F9A852', color: 'white' },
  'Validación Visita y Cotejo': { backgroundColor: '#F69653', color: 'white' },
  'Validación de Cuenta': { backgroundColor: '#F38654', color: 'white' },
  'Generación de contrato': { backgroundColor: '#ED6856', color: 'white' },
  'Validación de Contrato': { backgroundColor: '#EF5956', color: 'white' },
  Firma: { backgroundColor: '#EE4C58', color: 'white' },
  'Validación de contrato firmado': {
    backgroundColor: '#EE4C58',
    color: 'white',
  },
  Dispersión: { backgroundColor: '#641e16', color: 'white' },
  Cliente: { backgroundColor: '#56A287', color: 'white' },
};

export const getStyleEstatus = nombre => {
  let result = styles_estatus[nombre];
  return result || { backgroundColor: '#212f3d', color: 'white' };
};

export const getStyleEtapa = nombre => {
  let result = styles_etapa[nombre];
  return result || { backgroundColor: '#212f3d', color: 'white' };
};

export const getStyleSubEtapa = nombre => {
  let result = styles_subetapa[nombre];
  return result || { backgroundColor: '#212f3d', color: 'white' };
};

const getRandomStyle = () => {
  var item = styles[Math.floor(Math.random() * styles.length)];
  return item;
};

export default getRandomStyle;
