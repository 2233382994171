import axios from 'axios';
import { apiRoot } from '../constants';
import { getCookiesValue, logOut } from '../components/Helpers';

const axiosInstance = axios.create({
    baseURL: apiRoot,
    timeout: 30 * 1000,
    headers: {}
});

axiosInstance.interceptors.request.use(function (config) {
    let cookiesAcces = getCookiesValue('accesstoken');
    if (cookiesAcces) {
        config.headers['access_token'] = cookiesAcces;
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if ( error && error.response && error.response.status === 401 ) {
        logOut();
        return;
    }
    return Promise.reject(error);
});

export default axiosInstance;