import React from 'react';

const Notifications = ({ notificationsList }) => {
  return (
    <>
      {notificationsList.length === 0 ? (
        <>No hay notificaciones pendientes</>
      ) : (
        notificationsList.map((notificacion, index) => {
          return (
            <div key={index} className={`notification_item info`}>
              {notificacion.estatus === 'pendiente' && (
                <div className="notification_dot">
                  <span />
                </div>
              )}
              <span>{notificacion.mensaje}</span>
            </div>
          );
        })
      )}
    </>
  );
};

export default Notifications;
