import React from 'react';
import { Label, Input, Row, Col, FormGroup } from 'reactstrap';

const PreguntaInput = ({ index, tipo, label, opciones, value, onChange, required }) => {

    const inputType = (opciones) => {
        let type = opciones && opciones[0] ? opciones[0] :'text';
        return type;
    };

    const LabelRequired = () => {
        if( required ){
            return <span style={{ color: 'red'}}>*</span>;
        }
        return null;
    };

    if (tipo === 'dropdown') {
        return (
            <Row>
                <Col xs={12} sm={12} md={12}>
                    <FormGroup>
                        <Label>{label}<LabelRequired /></Label>
                        <Input
                            name={`input_${index}`}
                            type="select"
                            className="select-arrows inputMobile"
                            value={value}
                            onChange={event => onChange(event.target.value)}
                        >
                            <option value="">Selecciona</option>
                            {(opciones || []).map((opcion, index) => {
                                return <option key={index} value={opcion}>{opcion}</option>;
                            })}
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
        )
    }

    if (tipo === 'input') {
        return (
            <Row>
                <Col xs={12} sm={12} md={12}>
                    <FormGroup>
                        <Label>{label}<LabelRequired /></Label>
                        <Input
                            type={inputType(opciones)}
                            name={`input_${index}`}
                            className="inputMobile"
                            value={value}
                            onChange={event => onChange(event.target.value)}
                       />
                    </FormGroup>
                </Col>
            </Row>
        )
    }

    return null;
}

export default PreguntaInput;