import React, { useState, useRef } from 'react';
import { Card, Col, Row, Table } from 'reactstrap';
import { Buttons } from '../../../../../Atoms/buttons';
import '../../../../../../styles/_overrides.scss';
import { dateFormat } from '../../../../../Helpers';
import Loading from '../../../../../Atoms/loading/Loading';
import { Link } from 'react-router-dom';
import {
  getStyleEtapa,
  getStyleSubEtapa,
  getStyleEstatus,
} from '../../../../../../utils/randomStyle';

export const TableSticky = ({ tableAutorizacionData, isLoading }) => {
  const MensajesTabla = ({ numero_resultados }) => {
    if (isLoading) {
      return (
        <tr>
          <th colSpan="7">
            <Loading />
          </th>
        </tr>
      );
    }

    if (numero_resultados == 0) {
      return (
        <tr>
          <td colSpan="7">No se han encontrado resultados</td>
        </tr>
      );
    }

    return null;
  };

  return (
    <>
      <div className="scrollSticky">
        <>
          <Row className="p-3 margin-t">
            <Col>
              <Card className="mb-3 backgroundTransparent">
                <Table
                  responsive
                  className="tableSticky border2xWhite tableScrollNone marginT16"
                >
                  <thead>
                    <tr className="trSticky">
                      <th className="">
                        <div className="with200">Cliente</div>
                      </th>
                      <th>Número solicitud</th>
                      <th>Fecha de Autorización</th>
                      <th>Etapa</th>
                      <th>
                        <div className="with200">Sub etapa</div>
                      </th>
                      <th>Estatus</th>
                      <th>
                        <div className="with200">Tiempo Total</div>
                      </th>
                      <th>
                        <div className="with200">Tiempo en subetapa</div>
                      </th>
                      <th>Comentarios</th>
                      <th>Fecha Validación</th>
                      <th>Fecha Dipersión</th>

                      <th>Asesor</th>
                      <th>Team Leader</th>
                      <th>Relationship M.</th>
                      <th>Onboardings</th>
                      <th>Macrocanal</th>
                      <th>Canal</th>
                      <th>Código de Origen</th>
                      <th>Responsable Actual</th>
                      <th>
                        <div className="div_content-max">Etapa Anterior</div>
                      </th>
                      <th>El cliente no tiene interés</th>
                      <th>
                        <div className="div_content-max">Rechazar</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <MensajesTabla
                      numero_resultados={tableAutorizacionData.length}
                    />

                    {!isLoading &&
                      tableAutorizacionData.map((table, index) => {
                        return (
                          <tr key={index}>
                            <td>{table.nombrecliente}</td>
                            <td>
                              <Link
                                text={table.numsolicitud}
                                className="buttonHref"
                                to={`/reporte-numero-solicitud/${table.numsolicitud}`}
                              >
                                {table.numsolicitud}
                              </Link>
                            </td>
                            <td>{dateFormat(table.fechainicio)}</td>
                            <td style={getStyleEtapa(table.etapa)}>
                              {table.etapa}
                            </td>
                            <td style={getStyleSubEtapa(table.subetapa)}>
                              {table.subetapa}
                            </td>
                            <td style={getStyleEstatus(table.estatus)}>
                              {table.estatus}
                            </td>
                            <td>{table.tiempototal}</td>
                            <td>{table.tiempoestatus}</td>
                            <td>{table.comentario}</td>
                            <td>{dateFormat(table.fechavalidacion)}</td>
                            <td>{dateFormat(table.fechadispercion)}</td>
                            <td>{table.asesor}</td>
                            <td>{table.teamleader}</td>
                            <td>{table.relationshipmanager}</td>
                            <td>{table.onboardings}</td>
                            <td>{table.macrocanal}</td>
                            <td>{table.canal} </td>
                            <td>{table.codigoorigen}</td>
                            <td>{table.responsableactual}</td>
                            <td className="padding0">{table.razonregreso}</td>
                            <td>{table.razonesperdidainteres}</td>
                            <td className="padding0">{table.rechazo}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </>
      </div>
    </>
  );
};
