import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { EmptyLayout, LayoutRoute } from './components/Layout';
import './styles/reduction.scss';
import './styles/_sticky_table.scss';
import DashboardPage from './pages/DashboardPage';

import { Credito } from './pages/pantallas/credito';

import { ReporteSolicitud } from './pages/pantallas/reportes/reporteSolicitud';
import { ReporteNumSolicitud } from './pages/pantallas/reportes/reporteNumSolicitud';

import { GestiondeUsuarios } from './pages/pantallas/accesos/gestiondeUsuarios';

import PrivateRoute from './routes/privateRoute';

import { AsesorTeamleaderenAlianzas } from './pages/pantallas/flujos/asesorTeamleaderenAlianzas';
import { AreadeDictaminacion } from './pages/pantallas/flujos/areadeDictaminacion';
import { AreadeOnboarding } from './pages/pantallas/flujos/areadeOnboarding';
import { MesadeCredito } from './pages/pantallas/flujos/mesadeCredito';
import { MesadeControl } from './pages/pantallas/flujos/mesadeControl';
import { RelationshipManagers } from './pages/pantallas/flujos/relationshipManagers';
import { Tesoreria } from './pages/pantallas/flujos/tesoreria';

import AuthPage from './pages/AuthPage';
import AuthPageInternal from './pages/AuthPageInternal';
import Toaster from './components/Atoms/toast/Toast';
import { Cobranza } from './pages/pantallas/flujos/cobranza';

const App = props => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <LayoutRoute
            exact
            path="/login"
            layout={EmptyLayout}
            component={AuthPage}
          />
          <LayoutRoute
            exact
            path="/logininterno"
            layout={EmptyLayout}
            component={AuthPageInternal}
          />
          <PrivateRoute exact path="/" component={DashboardPage} />
          <PrivateRoute exact path="/DashboardPage" component={DashboardPage} />
          <PrivateRoute exact path="/consulta-credito" component={Credito} />
          <PrivateRoute
            exact
            path="/reporte-solicitudes"
            component={ReporteSolicitud}
          />
          <PrivateRoute
            exact
            path="/reporte-numero-solicitud/:id"
            component={ReporteNumSolicitud}
          />
          <PrivateRoute
            exact
            path="/gestion-de-usuarios"
            component={GestiondeUsuarios}
          />
          <PrivateRoute
            exact
            path="/asesor-team-leader-en-alianzas"
            component={AsesorTeamleaderenAlianzas}
          />
          <PrivateRoute
            exact
            path="/area-de-dictaminacion"
            component={AreadeDictaminacion}
          />
          <PrivateRoute
            exact
            path="/area-de-onboarding"
            component={AreadeOnboarding}
          />
          <PrivateRoute
            exact
            path="/mesa-de-credito"
            component={MesadeCredito}
          />
          <PrivateRoute
            exact
            path="/mesa-de-control"
            component={MesadeControl}
          />

          <PrivateRoute
            exact
            path="/relationship-managers"
            component={RelationshipManagers}
          />
          <PrivateRoute exact path="/tesoreria" component={Tesoreria} />
          <PrivateRoute exact path="/cobranza" component={Cobranza} />
          <Route path="*" render={() => <Redirect to="/login" />} />
        </Switch>
      </BrowserRouter>
      <Toaster />
    </>
  );
};

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
