import React from 'react';
import './style.css'

export const FooterLogin = () => {

    return (
        <footer className="w-full footer">
            <div className="col-md-8">
                    <span className="textFooter"> 
                    Unifin Credit S.A. de C.V. SOFOM, E.N.R. y/o Click Pyme, S.A. de C.V., SOFOM, E.N.R., para su constitución y operación con tal carácter, no requiere autorización de la Secretaría de Hacienda y Crédito Público, no obstante, se encuentra sujeta a la supervisión de la Comisión Nacional Bancaria y de Valores, únicamente para efectos de los dispuesto por el artículo 56 de la Ley General de Organizaciones y Actividades Auxiliares del Crédito. 
                    </span><br/><br/>
                    <span className="textFooter"> 
                    Pipeline de ventas © 2022 Derechos Reservados. 
                    </span>
            </div>
        </footer>
    )
}