import React, { useState } from 'react';
import { ModalBody, FormGroup, Label, Col, Input } from 'reactstrap';
import toast from 'react-hot-toast';
import iconMoreWhite from '../../../../assets/img/icons/iconMoreWhite.png';
import axiosInstance from '../../../../utils/axiosInstance';
// import './style.css';

export const ModalComentarios = ({ solicitud, handleModalClose }) => {
  const [showAddComentarios, setshowAddComentarios] = useState(false);
  const [textComentario, setTextComentario] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setshowAddComentarios(!showAddComentarios);
  };

  const handleChange = event => {
    setTextComentario(event.target.value);
  };

  const handleClickSave = async () => {
    try {
      setIsLoading(true);
      let data = {
        numsolicitud: solicitud.numsolicitud,
        comentario: textComentario,
        idworkflow: 1,
      };
      await axiosInstance.post(`/solicitudes/comentarios`, data);
      toast.success('Comentario guardado corretamente');

      setTimeout(() => {
        // handleId();
        window.location.reload();
      }, 1200);

      handleModalClose();
    } catch (error) {
      toast.error('Error al guardar el comentario');
    } finally {
      setIsLoading(false);
    }
  };

  const handleId = async () => {
    try {
      let result = await axiosInstance.get(
        `/solicitudes/consulta_solicitud?id_workflow=1&id_solicitud=${solicitud.numsolicitud}`,
      );
      console.log('Data', result.data.solicitud);
    } catch (error) {
      toast.error('Error al consultar id por solicitud');
    } finally {
    }
  };
  return (
    <>
      <div className="popupcontent modal fade show">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <span className="titleHeader">Comentarios</span>
              <div>
                <img
                  src={iconMoreWhite}
                  className="imgMore1 marginTN4"
                  alt="iconMoreWhite"
                  onClick={handleClick}
                />
                <button type="button" className="close" aria-label="Close">
                  <span aria-hidden="true" onClick={handleModalClose}>
                    ×
                  </span>
                </button>
              </div>
            </div>
            <ModalBody className="modal_custom">
              {(!solicitud.comentarios ||
                solicitud.comentarios.length === 0) && (
                <FormGroup row>
                  <Label for="exampleMonto" className="formStyle-us" sm={12}>
                    No tienes comentarios
                  </Label>
                </FormGroup>
              )}

              {solicitud.comentarios &&
                solicitud.comentarios.map((comentario, index) => (
                  <div key={index}>
                    <FormGroup row>
                      <Col sm={12}>
                        <Input
                          placeholder={comentario.comentario}
                          type="number"
                          id="number"
                          disabled
                          name="Monto"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col
                        sm={12}
                        style={{
                          textAlign: index % 2 === 0 ? 'right' : 'left',
                        }}
                      >
                        <span>{comentario.correo}</span>
                      </Col>
                    </FormGroup>
                  </div>
                ))}

              {showAddComentarios && (
                <>
                  <FormGroup row>
                    <Label for="exampleMonto" className="formStyle-us" sm={4}>
                      Agregar comentario
                    </Label>
                    <Col sm={8}>
                      <Col className="p-3 paddingg0">
                        <Input
                          type="textarea"
                          name="text"
                          className="textAreaHeigth"
                          onChange={handleChange}
                          disabled={isLoading}
                        />
                      </Col>
                    </Col>
                  </FormGroup>

                  <div className="modal-footer mfooter">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={handleModalClose}
                      disabled={isLoading}
                    >
                      Cancelar
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={handleClickSave}
                      disabled={isLoading}
                    >
                      Guardar
                    </button>
                  </div>
                </>
              )}
            </ModalBody>
          </div>
        </div>
      </div>
    </>
  );
};
