import React, { useState, useEffect } from 'react';
import {
  Col,
  Card,
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
} from 'reactstrap';
import { MdSearch } from 'react-icons/md';
import { Options } from '../modals/options/index';
import './style.css';

export const Search = ({
  placeholder,
  usersAll,
  updateTable,
  updateMessage,
}) => {
  const [value, setValue] = useState({
    value: '',
  });

  const handleChange = event => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (usersAll.length > 0 && value.length > 0) {
      const keys = Object.keys(usersAll[0]);

      const usersFiltered = usersAll.filter(user => {
        for (let k in keys) {
          if (user[keys[k]].includes(value)) {
            return user[keys[k]].includes(value);
          }
        }
      });

      /*
        const usersFiltered = usersAll.filter(user => {
            return user.correo.includes(value);
      })
      */

      // igual a 0
      updateTable(usersFiltered);
      if (usersFiltered.length === 0) {
        updateMessage('No se encontraron resultados de busqueda');
      } else {
        updateMessage('');
      }
    }

    if (value === '') {
      updateTable(usersAll);
      updateMessage('');
    }
  }, [value]);

  return (
    <>
      <div className="p-3 searchg">
        <Col md={5} className="mobileSearch">
          <Card className="search-style button-search-style">
            <InputGroup className="button-search-style">
              <Input
                placeholder={placeholder}
                className="search-style inputplaceh"
                onChange={handleChange}
              />
              <InputGroupAddon
                addonType="append"
                className="button-search-style"
              >
                <Button color="secondary" className="button-search-style">
                  <MdSearch />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Card>
        </Col>
      </div>
      <div className="options text-right p-3">
        <Options titleAdd="Agregar  Usuario +" />
      </div>
    </>
  );
};
