import React from 'react';
import { Title } from '../../../components/Atoms/title'
import { Tables } from '../../../components/Moleculas/pantallas/credito/consulta/tables'
import { Search } from '../../../components/Moleculas/pantallas/credito/consulta/search'

export const Credito = () => {
    const onSearch = () =>{

    }
    return (
    <>

        <Title text="Consulta de Crédito"/>
        <Search 
            onSearch={onSearch}
            placeholder="Número de Crédito"
        />
        <Tables
                titleTable1="N.º"
                titleTable2="Número de Crédito"
                titleTable3="Etapa "
                titleTable4="Sub-Etapa"
                titleTable5="Fecha de Asignación"
    
                content1="120"
                content2="Autorización"
                content3="Autorizado"
                content4="02/05/2010"
    
                content5="121"
                content6="Reuniendo Expediente"
                content7="Documentos y Actas"
                content8="20/11/2017"
    
                content9="123"
                content10="Reuniendo Expediente"
                content11="Dictamen"
                content12="01/12/2017"
    
                content13="124"
                content14="Reuniendo Expediente"
                content15="Formatos"
                content16="14/01/2010"
    
    
                content17="125"
                content18="Validación de Expediente"
                content19="Validación Expediente"
                content20="25/05/2003"

                content21="126"
                content22="Contratación"
                content23="Validación Visita y Cotejo"
                content24="06/03/2022"
        />
    </>
    );
};
