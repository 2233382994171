import {Login} from '../components/Login';
import React from 'react';
import { HeaderLogin } from '../components/Layout/Login/headerLogin';
import { FooterLogin } from '../components/Layout/Login/footerLogin';


const AuthPage = ()=> {
    return (
      <>
      <HeaderLogin/>
      <Login/>
      <FooterLogin/>

      </>
    );
}

export default AuthPage;
