import React from 'react';

const Footer = () => {
  return (
     /*   <div className="p-3 text-right">
          Pipeline de ventas © 2022 Derechos Reservados.
        </div>*/
        <></>
  );
};

export default Footer;
