import React from 'react';
import '../styles/_overrides.scss';
import fernet from 'fernet';
import { AsesorTeamleaderenAlianzas } from './pantallas/flujos/asesorTeamleaderenAlianzas';
import { RelationshipManagers } from './pantallas/flujos/relationshipManagers';
import { AreadeDictaminacion } from './pantallas/flujos/areadeDictaminacion';
import { AreadeOnboarding } from './pantallas/flujos/areadeOnboarding';
import { Tesoreria } from './pantallas/flujos/tesoreria';
import { MesadeControl } from './pantallas/flujos/mesadeControl';
import { MesadeCredito } from './pantallas/flujos/mesadeCredito';
import { Cobranza } from './pantallas/flujos/cobranza';
import { getCookiesValue, logOut } from '../components/Helpers';
import { withRouter } from 'react-router-dom';
import { eliminarCookies } from '../components/Helpers';
import { GestiondeUsuarios } from './pantallas/accesos/gestiondeUsuarios';
import { tokenSecret } from '../constants';

const DashboardPage = props => {
  const cookiesAcces = getCookiesValue('accessuser');
  var secret = new fernet.Secret(tokenSecret);

  var token = new fernet.Token({
    secret: secret,
    token:
      'gAAAAABSO_yhAAECAwQFBgcICQoLDA0OD1PGoFV6wgWZG6AOBfQqevwJT2qKtCZ0EjKy1_TvyxTseR_3ebIF6Ph-xa2QT_tEvg==',
    ttl: 0,
  });

  try {
    const descode = token.decode(cookiesAcces);
    if (descode === 'admin' || descode === 'Admin') {
      return <AsesorTeamleaderenAlianzas />;
    } else if (
      descode === 'Asesor / Teamleader en Alianzas' ||
      descode === 'asesor / Teamleader en Alianzas'
    ) {
      return <AsesorTeamleaderenAlianzas />;
    } else if (
      descode === 'Teamleader en Alianzas' ||
      descode === 'teamleader en Alianzas'
    ) {
      return <AsesorTeamleaderenAlianzas />;
    } else if (
      descode === 'relationship managers' ||
      descode === 'Relationship Managers'
    ) {
      return <RelationshipManagers />;
    } else if (
      descode === 'area de onboarding' ||
      descode === 'Área de Onboarding'
    ) {
      return <AreadeOnboarding />;
    } else if (
      descode === 'Área de Onboarding - Skip	' ||
      descode === 'Área de Onboarding - Skip	'
    ) {
      return <AreadeOnboarding />;
    } else if (
      descode === 'area de dictaminación' ||
      descode === 'Área de Dictaminación'
    ) {
      return <AreadeDictaminacion />;
    } else if (descode === 'mesa de crédito' || descode === 'Mesa de Crédito') {
      return <MesadeCredito />;
    } else if (descode === 'mesa de Control' || descode === 'Mesa de Control') {
      return <MesadeControl />;
    } else if (descode === 'tesorería' || descode === 'Tesorería') {
      return <Tesoreria />;
    } else if (descode === 'cobranza' || descode === 'Cobranza') {
      return <Cobranza />
    } else if (descode === 'admin ti' || descode === 'Admin TI'){
      return <GestiondeUsuarios />
    }else {
      window.location.reload();
      eliminarCookies('accesstoken');
      eliminarCookies('accessuser');
      window.location.href = '/login';
    }
  } catch (error) {
    window.location.reload();
    eliminarCookies('accesstoken');
    eliminarCookies('accessuser');
    window.location.href = '/login';
  }
};

export default withRouter(DashboardPage);
